import { useState } from "react";
import {
  Modal,
  notification,
} from "antd";
import axios from "axios";
import AddPatientForm from "../../private/AddPatientForm/AddPatientForm";
import dayjs from "dayjs";

const EditPatientModal = ({
  title,
  IsEdit,
  showEditModal,
  handleCancel,
  editPatientForm,
  patientInfo,
  clinicInfo,
  getPatientInformation
}) => {
  const [touched, setTouched] = useState(false);
  const [confimLoader, setConfirmLoader] = useState(false);

  function checkUndefined(val) {
    return val ? val : "";
  }

  const onPatientSubmit = (values) => {
    setConfirmLoader(true);
    editPatientForm
      .validateFields()
      .then(async (value) => {
        const apiUrl =
          process.env.REACT_APP_BASE_URL.replace("/lab", "") +
          "patient/editPatient";

        const payload = {
          patientId: patientInfo?.patient_id,
          firstName: value?.firstName,
          lastName: value?.lastName,
          email: value?.Email,
          isUnderAge: false,
          phoneNumber: value?.phone,
          clinicId: clinicInfo.clinic_id,
          countryCode:value?.phone?  value.prefix:null,
          Address: checkUndefined(value?.Address),
          county: checkUndefined(value?.county),
          Zipcode: checkUndefined(value?.Zipcode),
          city: checkUndefined(value?.city),
          country: checkUndefined(value?.country),
          dob: value?.dob ? dayjs(value.dob).format("YYYY-MM-DD") : null,
          gender: value?.gender,
           genderSelfDescribe: value?.genderSelfDescribe,
           addressId: value?.addressId
        };
        if (clinicInfo?.companyType) {
          payload.companyType = clinicInfo.companyType;
        }
        const res = await axios.put(apiUrl, payload);
        
        if (res?.data?.body?.msg) {
          notification.success({ message: res?.data?.body?.msg });
          handleCancel();
          getPatientInformation()
        }
        else{
          notification.error({ message: res?.data?.err?.msg||"Some error occured" });
        }
        setConfirmLoader(false);
      })
      .catch((error) => {
        setConfirmLoader(false);
        console.error(error);
      });
  };
  return (
    <Modal
      title={title}
      width={700}
      open={showEditModal}
      onOk={onPatientSubmit}
      confirmLoading={confimLoader}
      onCancel={handleCancel}
      okButtonProps={{
        style: { width: "7rem", borderRadius: "30px" },
      }}
      cancelButtonProps={{ style: { width: "7rem", borderRadius: "30px" } }}
    >
      <AddPatientForm
        addPatientForm={editPatientForm}
        onPatientSubmit={onPatientSubmit}
        associatedClinics={[]}
        touched={touched}
        setTouched={setTouched}
        IsEdit={IsEdit}
        patientInfo={patientInfo}
      />
    </Modal>
  );
};

export default EditPatientModal;
