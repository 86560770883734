import { useEffect, useState } from "react";
import { Form, message } from "antd";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import AppModal from "components/shared/AppModal/AppModal";
import Phases from "../../Common/Phases/Phases";
import { savePhaseOrder } from "api/treatment";
dayjs.extend(utc);
const PhasesModal = ({
  showPhasesModal,
  setShowPhasesModal,
  phasingList,
  upperLength,
  lowerLength,
  treatmentPlans,
}) => {
  const [form] = Form.useForm();
  const [loading, setIsLoading] = useState(false);

  useEffect(() => {
    form.setFieldValue(
      "phases",
      phasingList.map(el => ({
        ...el,
        batch: true,
        deliveryDate: el.deliveryDate ? dayjs.utc(el.deliveryDate) : null,
      }))
    );
  }, [phasingList, form]);

  const onOk = e => {
    // e.stopPropagation();

    form.submit();
  };

  const onCancel = e => {
    // e.stopPropagation();
    setShowPhasesModal(false);
  };

  const onFinish = async values => {
    // console.log(values);
    // let alignerCount = 0;
    // values.phases.forEach(el => {
    //   alignerCount = alignerCount + el.alignerIds.length;
    // });

    const lastAlignerId = values?.phases?.reduce((_, current) => {
      const lastValue = current.alignerIds?.[current.alignerIds.length - 1];
      return lastValue !== undefined ? lastValue : _;
    }, null);

    let totalLength = upperLength;

    if (upperLength < lowerLength) {
      totalLength = lowerLength;
    }

    if (totalLength > lastAlignerId) {
      return message.error("Please select all aligners in phases");
    }

    setIsLoading(true);
    try {
      await savePhaseOrder({
        patient_order_id: phasingList[0].patient_order_id,
        phases: values.phases
          .map((el, index) => ({
            ...el,
            phaseNo: index + 1,
            deliveryDate: dayjs(el.deliveryDate).format("YYYY-MM-DD"),
          }))
          .filter(el => !el?.patient_order_id),
        edit: true,
        lab_treatment_id: phasingList[0].lab_treatment_id,
      });

      message.success("Phases updated successfully");
      treatmentPlans();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
    onCancel();
  };

  return (
    <AppModal
      confirmLoading={loading}
      width={1200}
      title="Add/Edit Phases"
      open={showPhasesModal}
      onOk={onOk}
      onCancel={onCancel}
      okText="Update"
    >
      <Form
        onFinish={onFinish}
        form={form}
        layout="vertical"
        name="add/edit-phases"
      >
        <div style={{ border: '1px dashed #D7D6D6', borderRadius: '10px', padding: '25px'}}>
        <Phases
          hasCheckbox={false}
          upperLength={upperLength}
          lowerLength={lowerLength}
          form={form}
        />
        </div>
      </Form>
    </AppModal>
  );
};

export default PhasesModal;
