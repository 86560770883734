import { returnErrors } from "./errorActions";
import { Auth } from "aws-amplify";
import errorModal from "../../../components/shared/modals/errorModal";
// import { labApiTasks } from "../../../models/lab";
import { firstLoginCheck } from "api/lab";
import { loadLab, clearLab } from "./labActions";

import {
  USER_LOADED,
  USER_LOADING,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  SET_TOKEN,
} from "./types";

export const tokenExpired = () => async (dispatch) => {
  dispatch({
    type: AUTH_ERROR,
  });
};
// Check token & load user
export const loadUser = () => async (dispatch, getState) => {
  // User loading
  dispatch({ type: USER_LOADING });

  try {
    const user = await Auth.currentAuthenticatedUser();
    localStorage.setItem("idToken", user.signInUserSession.idToken.jwtToken);

    await dispatch({
      type: USER_LOADED,
      payload: user,
    });
    await dispatch(loadLab());
  } catch (error) {
    await dispatch(returnErrors(error, error.code));
    await dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const setToken = (token) => async (dispatch, getState) => {
  localStorage.setItem("idToken", token);
  await dispatch({ type: SET_TOKEN, payload: { token } });
};

// Login User
export const login =
  (username, password, routerHistory, setIsSpinning, location) => async (dispatch) => {
    try {
      const user = await Auth.signIn(username, password);

      localStorage.setItem("idToken", user.signInUserSession.idToken.jwtToken);

      // const res = await labApiTasks.getAll("lab/checkClinicFirstLogin", {
      //   labEmail: username,
      // });

      const res = await firstLoginCheck(username, user.signInUserSession.idToken.jwtToken);

      setIsSpinning(false);
      const isFirstLogin = Boolean(res.data.data.firstLogin);
      user.isFirstLogin = isFirstLogin;

      await dispatch({
        type: LOGIN_SUCCESS,
        payload: user,
      });

      await dispatch(loadLab());

      // testing, change to true check
      if (isFirstLogin) {
        routerHistory.push("/welcome-page");
      } else {
        let { from } =
          // location.state ||
          { from: { pathname: "/lab/dashboard" } };
        routerHistory.push(from);
      }
      window.location.reload();
      // setIsSpinning(false);

      // const res = await await labApiTasks.getAll(
      //   "clinic/checkClinicFirstLogin",
      //   { clinicEmail: username }
      // );

      // const isFirstLogin = res.body.data[0].firstLogin === 0 ? false : true;
      // user.isFirstLogin = isFirstLogin;
      // user.isFirstLogin = true;

      // if (!isFirstLogin) {
      //   routerHistory.push("/welcome-page");
      // } else {
      //   routerHistory.push("/onboarding");
      // }

      // await dispatch({
      //   type: LOGIN_SUCCESS,
      //   payload: user,
      // });
      // await dispatch(loadLab())

      // routerHistory.push("/welcome-page");
    } catch (error) {
      console.log("error signing in", error);
      setIsSpinning(false);

      errorModal(error.message == "Incorrect username or password."? "Incorrect username, password or portal.": error.message);

      await dispatch(returnErrors(error, error.code));
      await dispatch({
        type: LOGIN_FAIL,
      });
    }
  };

// Logout User
export const logout = () => async (dispatch) => {
  try {
    await Auth.signOut();
    dispatch({
      type: LOGOUT_SUCCESS,
    });
    localStorage.clear();
    dispatch(clearLab());
    window.location.reload();
  } catch (error) {
    console.log("error signing out: ", error);
  }

  // await axiosAuthInstance()
  //   .post("/signout")
  //   .then((res) => {
  //     console.log(res, "logout response");
  //     dispatch({
  //       type: LOGOUT_SUCCESS,
  //     });
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //   });
};
