import api from "./api";

export const getSummaryNotificationsAPI = (labId) => {
  return api({
    method: "GET",
    url: `http://localhost/api/v1/lab/notifications/get/${labId}`,
  });
};

export const getSummaryEmailsAPI = (labId) => {
  return api({
    method: "GET",
    url: `clinic/notifications/summaryEmails/get/${labId}?module=lab`,
  });
};

export const deleteSummaryEmailsAPI = (data) => {
  return api({
    method: "DELETE",
    url: `/clinic/notifications/summaryEmails/delete`,
    data: data,
  });
};

export const updateSummaryEmailsAPI = (data) => {
  return api({
    method: "POST",
    url: `/clinic/notifications/summaryEmails/add`,
    data: data,
  });
};

export const updateNotificationsAPI = (data) => {
  return api({
    method: "PUT",
    url: `/lab/notifications/update`,
    data: data,
  });
};


export const assignStaffToNotificationAPI = (data) => {
  return api({
    method: "POST",
    url: `/notifications/assignStaff`,
    data: data,
  });
};

export const unAssignStaffToNotificationAPI = (data) => {
  return api({
    method: "POST",
    url: `/notifications/unAssignStaff`,
    data: data,
  });
};
