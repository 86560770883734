import { Input, AutoComplete, Divider } from "antd";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { searchPatientsAPI } from "services/api/patients";
import "./searchBar.scss";

const SearchBar = ({ labId, onSelectCallback }) => {
  const [searchValue, setSearchValue] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const getSearchData = () => {
    setLoading(true);
    searchPatientsAPI(searchValue, labId)
      .then((res) => {
        setOptions((prev) => {
          return res?.data?.data?.length ? res.data.data.map((el) => {
            return {
              ...el,
              label: (
                <>
                  <div>
                    <h3  style={{display:"flex", margin: "0", textTransform: "capitalize" }}>
                      <span className="search-name-label">{el.name}</span> {el.type === "patient" ? "(Patient)" : "(Partner)"}
                    </h3>
                    <div>{el.email}</div>
                    <div>{el.phoneNo}</div>
                  </div>
                  <Divider style={{ margin: "0" }} />
                </>
              ),
              value: el.id,
            };
          }) :
            [{ value: "No results found", disabled: true }];
        });
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    let delay = setTimeout(() => {
      if (searchValue) {
        getSearchData();
      }
    }, 1100);

    return () => clearTimeout(delay);
  }, [searchValue]);

  const onSelect = (value, option) => {
    onSelectCallback && onSelectCallback(option);
  };

  const onSearch = (value) => {
    setSearchValue(value.trim());
  };

  return (
    <div className="search-bar-wrapper">
      <AutoComplete
        filterOption={false}
        popupMatchSelectWidth={252}
        options={options}
        style={{ width: "100%" }}
        autoFocus
        onSelect={onSelect}
        onSearch={onSearch}
        size="large"
      >
        <Input.Search loading={loading} notFoundContent="No results found" placeholder="Search Patient ID, email, name, phone, DOB" />
      </AutoComplete>
    </div>
  );
};
export default SearchBar;

SearchBar.propTypes = {
  onSelectCallback: PropTypes.func.isRequired,
  labId: PropTypes.string,
};

SearchBar.defaultProps = {
  labId: "",
};
