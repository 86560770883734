// import { useState, useEffect } from "react";
import { Card, Spin } from "antd";

const Box = ({ children, isLoading, hoverable , cardTitle, className}) => {
  return (
    <Card className={className} title={cardTitle} hoverable={hoverable}>
      <Spin spinning={isLoading}>{children}</Spin>
    </Card>
  );
};

export default Box;
