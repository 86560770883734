import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getAllTreatmentPlans } from "api/lab";
import { Spin } from "antd";
import AttachmentFrame from "./Components/AttachmentFrame/AttachmentFrame";
import IprFrame from "./Components/IprFrame/IprFrame";
import "./frame.scss";

const IprWebFrame = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const patientIdParams = queryParams.get("patientId");
  const chartParamsValue = queryParams.get("chart") || "fdi";
  const treatmentVersionPrams = queryParams.get("treatmentVersion");
  const excludeAlignerCountsParam = queryParams.get("alignerCounts");
  const initialFrameType = queryParams.get("frameType") || "ipr"; // New query parameter
  const excludeAlignerCounts = excludeAlignerCountsParam
    ? excludeAlignerCountsParam.split(",").map(Number)
    : [];
  const hideTeethParam = queryParams.get("hideTeeth") || "";

  const [iprData, setIprData] = useState([]);
  const [attachmentData, setAttachmentData] = useState([]);
  const [chartValue, setChartValue] = useState(chartParamsValue);
  const [activeFrame, setActiveFrame] = useState(initialFrameType); // New state for frame selection
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (patientIdParams) {
      getTreatmentPlans();
    }
  }, [patientIdParams]);

  const filterAttachments = (attachments, excludeAlignerCounts) => {
    if (!Array.isArray(attachments)) return [];

    const excludeArray = Array.isArray(excludeAlignerCounts)
      ? excludeAlignerCounts
      : [excludeAlignerCounts];

    return attachments.filter(obj => !excludeArray.includes(obj.aligner_count));
  };

  const getTreatmentPlans = async () => {
    setLoading(true);
    const model = { patientId: patientIdParams };
    model.caseId = 1;
    try {
      const { data } = await getAllTreatmentPlans(model);
    //  console.log("ressssss", data.body.treatment_setup)
      const wholeTreatmentPlan = data?.body.treatment_setup;
      // console.log("wholeTreatmentPlanwholeTreatmentPlan", wholeTreatmentPlan)
      const activeTreatment = wholeTreatmentPlan.find(
        treatment => treatment.lab_treatment_id === treatmentVersionPrams
      );
      // console.log("activeTreatmentactiveTreatment", activeTreatment)
      if (activeTreatment) {
        //  console.log("activeTreatmentactiveTreatment", activeTreatment)
        let attachmentsFinal;
        //  console.log("activeTreatment", activeTreatment?.attachmentData);
        if (activeTreatment?.attachmentData) {
          attachmentsFinal = activeTreatment?.attachmentData;
          if (excludeAlignerCounts.length) {
            attachmentsFinal = filterAttachments(
              activeTreatment?.attachmentData,
              excludeAlignerCounts
            );
          }
          setAttachmentData(attachmentsFinal);
        }
        if (activeTreatment.iprData) {
          setIprData(activeTreatment.iprData);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="frame-container">
      <Spin spinning={loading}>
        {activeFrame === "attachment" ? (
          <AttachmentFrame
            chartValue={chartValue}
            attachmentData={attachmentData}
            hideTeethParam={hideTeethParam}
          />
        ) : (
          <IprFrame
            iprData={iprData}
            chartValue={chartValue}
            hideTeethParam={hideTeethParam}
          />
        )}
      </Spin>
    </div>
  );
};

export default IprWebFrame;
