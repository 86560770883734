import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Tooltip } from 'antd';
import { getAlignerBorder, getAlignerClassName, getAlignerBackground , getAlignerNumberBackground} from "utils/ipr";

const Aligners = ({ alignersObj, alignerType }) => {
const targetDivRef = useRef(null);
useEffect(() => {
  if (targetDivRef.current) {
    // Scroll to the bottom of the div
    targetDivRef.current.scrollTop = targetDivRef.current.scrollHeight;
    // targetDivRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
  }
}, [targetDivRef, alignersObj]);

  return (
    <div  ref={ alignerType=== "upper" ? targetDivRef : null} className={alignersObj.length > 6 ? "aligners-container aligner-scroll-container" :"aligners-container"}>
      {alignersObj.map((upperAligner, upperIndex) => {
        return (
          <div
            key={upperIndex}
            className={
              upperAligner.selected
                ? "selected-aligner aligner-rows"
                : "aligner-rows"
            }
          >
              <div className="aligner-info">
               <Tooltip placement="topRight" title={` Session
                  ${upperAligner.index ? upperAligner.index : upperIndex + 1}
                    Before Aligner ${upperAligner.name.split("-")[1]}`}>
               <div
                   style={{
                    background: `${getAlignerNumberBackground(
                      upperAligner.aligner_count
                    )} `
                  }}
               className={`aligner-num`}>
               S{upperAligner.name.split("-")[1]}
              </div>
               </Tooltip>
         
            </div>
            <div className={`aligner-row`}>
              {upperAligner.alignerValues.map((aligner, alignerIndex) => {
                return (
                  <div
                  style={{
                    background: `${getAlignerBackground(
                      aligner,
                      upperAligner
                    )} `,
                  }}
                    className={`aligner-circle aligner-${alignerType} 
                    ${
                      alignerType === "upper"
                        ? `${
                            aligner.value &&
                            upperIndex === alignersObj.length - 1
                              ? `aligner-0-upper`
                              : ""
                          }`
                        : aligner.value
                        ? `aligner-${upperIndex}-${alignerType} aligner-${alignerType}`
                        : ""
                    }
                    `}
                    key={alignerIndex}
                  >
                    {aligner.value}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Aligners;

Aligners.propTypes = {
  alignersObj: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        session: PropTypes.number.isRequired,
        // Add more PropTypes for other properties if needed
      })
    )
  ).isRequired,
};
