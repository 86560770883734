import { useState } from "react";
import { Select, Dropdown, Button } from "antd";
import AppTag from "components/shared/AppTag/AppTag";
import PhasesModal from "../PhasesModal/PhasesModal";
import styles from "./orderHeader.module.scss";
import { DownOutlined } from "@ant-design/icons";

const OrderHeader = ({
  treatmentPlan,
  order,
  onPhaseMenuChange,
  setShowPlaceLabOrder,
  setIsPhaseOrder,
  treatmentPlans,
}) => {
  // console.log("order", treatmentPlan);

  const { phasing = [], patient_order_id, phaseValue } = order;

  const { phasingList = [] } = treatmentPlan;

  const completedPhaseOrders = [];

  const pendingPhaseOrders = [];
  phasingList?.forEach(phase => {
    if (phase?.patient_order_id) {
      completedPhaseOrders.push(phase);
    } else {
      pendingPhaseOrders.push(phase);
    }
  });

  const [showPhasesModal, setShowPhasesModal] = useState(false);

  const items = [
    {
      key: "phaseOrder",
      label: "Order Next Phase",
      onClick: ({ item, key, keyPath, domEvent }) => {
        domEvent.stopPropagation();
        setIsPhaseOrder(patient_order_id);
        setShowPlaceLabOrder(true);
      },
    },
    {
      key: "editPhase",
      label: "Add/Edit Phase",
      onClick: ({ item, key, keyPath, domEvent }) => {
        domEvent.stopPropagation();

        setShowPhasesModal(true);
      },
    },
  ];

  if (!phasing || !phasing.length) {
    return patient_order_id;
  }

  return (
    <>
      {showPhasesModal && (
        <div onClick={e => e.stopPropagation()}>
          <PhasesModal
            upperLength={treatmentPlan?.num_of_upper_aligners}
            lowerLength={treatmentPlan?.num_of_lower_aligners}
            phasingList={phasingList}
            showPhasesModal={showPhasesModal}
            setShowPhasesModal={setShowPhasesModal}
            treatmentPlans={treatmentPlans}
          />
        </div>
      )}
      <div className={styles["order-header__container"]}>
        <div className={styles["order-header__first-col"]}>
          <div>{patient_order_id}</div>
          <AppTag customStyle={{ fontWeight: 500}} text={`Current Phase ${phaseValue}`} />
          <AppTag customStyle={{ fontWeight: 'normal'}} color="default" text={`Total Phases: ${phasingList.length}`} />
        </div>

        <div className={styles["order-header__second-col"]}>
          {pendingPhaseOrders?.length > 0 && (
            <Dropdown
              trigger={["click"]}
              onClick={event => event.stopPropagation()}
              menu={{
                items,
              }}
              placement="bottomRight"
            >
              <Button
                onClick={event => event.stopPropagation()}
                shape="round"
                type="primary"
              >
                Actions <DownOutlined />
              </Button>
            </Dropdown>
          )}
          <Select
            onChange={value => onPhaseMenuChange(value, patient_order_id)}
            value={order.phaseValue}
            onClick={event => event.stopPropagation()}
            style={{ width: "150px" }}
            options={phasing
              .map(el => {
                return {
                  label: `Phase ${el.phaseNo}`,
                  value: el.phaseNo,
                };
              })
              .sort((a, b) => b.value - a.value)}
          />
        </div>
      </div>
    </>
  );
};

export default OrderHeader;
