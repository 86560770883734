import { Link, useHistory } from "react-router-dom";
import { Card } from "antd";
import dayjs from "dayjs";
import classnames from "classnames";
import styles from "assets/styles/routes/dashboard.module.scss";
import { ReactComponent as Arrow } from "assets/images/Arrow.svg";
import { ReactComponent as Order } from "assets/images/Order.svg";
import { ReactComponent as OrderFirst } from "assets/images/OrderFirst.svg";
import css from "./styles.module.scss";
const OrdersTime = ({ orderData }) => {
  const history = useHistory();
  const onPhaseOverDueClick = () => {
    const yesterday = dayjs().subtract(1, "day").format("YYYY-MM-DD");
    const oneYearBack = "2024-01-01";
    const queryParams = new URLSearchParams({
      column: "phasingDate",
      startDate: oneYearBack,
      endDate: yesterday,
    }).toString();

    history.push(`/lab/patients?${queryParams}`);
  };

  const elementClass = classnames(styles.taskList, css["card-padding"]);
  const overDueClasses = classnames(
    styles.taskList,
    css["over-due__container"],
    css["card-padding"],
    {
      [css["is-overdue"]]: Boolean(orderData?.overduePhasingOrder),
    }
  );

  return (
    <Card className={css["card-element"]}>
      <div className={styles.taskContainer}>
        <h1 className={css["card-padding"]}>Orders</h1>
        <div className={elementClass}>
          <Link to="/lab/billing?status=all">
            <div className={styles.taskListItem}>
              <h2>{orderData?.allOrders}</h2>
              <div className={styles.taskListName}>
                <p>All Orders</p>
                <Arrow />
              </div>
              <div className={classnames(styles.orderLine, styles.line)} />
            </div>
          </Link>
          <Order />
        </div>
        <div className={elementClass}>
          <Link to="/lab/billing?status=first">
            <div className={styles.taskListItem}>
              <h3>{orderData?.firstOrder}</h3>
              <div className={styles.taskListName}>
                <p style={{ minWidth: "125px" }}>1st Order Invoices</p>
                <Arrow />
              </div>

              <div className={classnames(styles.firstLine, styles.line)} />
            </div>
          </Link>
          <OrderFirst />
        </div>

        <div className={overDueClasses} onClick={onPhaseOverDueClick}>
          <div className={styles.taskListItem}>
            <h3>{orderData?.overduePhasingOrder || 0}</h3>
            <div className={styles.taskListName}>
              <p style={{ minWidth: "150px" }}>Missing Phase Orders</p>
              <Arrow />
            </div>

            <div
              style={{
                background: Boolean(orderData?.overduePhasingOrder)
                  ? "red"
                  : "gray",
              }}
              className={`${styles.line}`}
            />
          </div>

          <OrderFirst className={css["over-due-icon"]} />
        </div>
      </div>
    </Card>
  );
};

export default OrdersTime;
