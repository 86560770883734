import { useState } from "react";
import { useSelector } from "react-redux";
import CurrentPast from "./CurrentPast/CurrentPast";
import FutureAnalytics from "./FutureAnalytics/FutureAnalytics";
import TabSwitcher from "./TabSwitcher";
import { getLabSelector } from "services/redux/selectors/commonSelectors";

const getTabs = props => ({
  1: <CurrentPast {...props} />,
  2: <FutureAnalytics {...props} />,
});

const Dashboard = () => {
  const lab = useSelector(getLabSelector);
  const [tabType, setTabTypes] = useState("1");

  const tabs = getTabs({ lab });
  return (
    <div>
      <TabSwitcher setTabTypes={setTabTypes} tabType={tabType} />
      {tabs[tabType]}
    </div>
  );
};

export default Dashboard;
