import React, { useState, useEffect } from "react";
import Attachments from "./Attachments";

const AttachmentFrame = ({ chartValue, attachmentData, hideTeethParam }) => {
  const [upperAttachments, setUpperAttachments] = useState([]);
  const [lowerAttachments, setLowerAttachments] = useState([]);

  const IprMapper = (aligners, type) => {
    let final;
    const prefix = type === "upper" ? "U" : "L";
    const mappedAligners = aligners.map(aligner => {
      return {
        ...aligner,
        name: `${prefix}${aligner.aligner_count}`,
        uniqueId: aligner.id,
      };
    });

    if (type === "upper") {
      final = mappedAligners.sort((a, b) => b.aligner_count - a.aligner_count);
    } else {
      final = mappedAligners.sort((a, b) => a.aligner_count - b.aligner_count);
    }

    return final;
  };

  const handleAttachments = data => {
    if (data?.length) {
      let upperAligners = data.filter(aligner => aligner.jawType === "upper");
      const lowerAligners = data.filter(aligner => aligner.jawType === "lower");
      upperAligners = upperAligners.reverse().map((obj, index) => {
        obj.index = index + 1;
        return obj;
      });
      const upperFinal = IprMapper(upperAligners.reverse(), "upper");
      const lowerFinal = IprMapper(lowerAligners, "lower");
      setUpperAttachments(upperFinal);
      setLowerAttachments(lowerFinal);
    }
  };

  useEffect(() => {
    if (attachmentData) {
      // console.log("attachmentData", attachmentData);
      handleAttachments(attachmentData || []);
    }
  }, [attachmentData]);

  //   useEffect(() => {
  //     let previousTooths = [];
  //     const mergedArray = lowerAttachments.length ? [...lowerAttachments] : []
  //     // if (mergedArray.length) {
  //       previousTooths = getTeethValuesWithTrue(mergedArray);
  //       // if (previousTooths.length) {
  //         const structure = [...lowerStructure];
  //         const updatedStructure = structure.map((Item) => {
  //           const matchingTooth = previousTooths.find(
  //             (tooth) => tooth == Item.id
  //           );
  //           return {
  //             ...Item,
  //             selected: matchingTooth ? true : false,
  //           };
  //         });

  //         setLowerStructure(updatedStructure);
  //       // }
  //     // }
  //   }, [lowerAttachments]);
  return (
    <div>
      <Attachments
        chartValue={chartValue}
        upperAttachments={upperAttachments}
        lowerAttachments={lowerAttachments}
        hideTeethParam={hideTeethParam}
      />
    </div>
  );
};

export default AttachmentFrame;
