import { io } from "socket.io-client";
import api from "./api";

const socketBaseUrl = process.env.REACT_APP_BASE_URL_SOCKET;
// const socketBaseUrl = "http://localhost:81";

const idToken = localStorage.getItem("idToken");

export const socket = io(socketBaseUrl, {
  path: "/api/v1/chat/socket",
  transports: ["websocket"],
  autoConnect: false,
  auth: {
    token: idToken ? idToken : "",
  },
  // withCredentials: true,
});

export const activitySocket = io(`${socketBaseUrl}/lab-activity`, {
  path: "/api/v1/chat/socket",
  transports: ["websocket"],
  autoConnect: false,
  auth: {
    token: idToken ? idToken : "",
  },
});

export const uploadChatFile = (data, config = {}) => {
  return api({
    method: "POST",
    url: socketBaseUrl + "/api/v1/chat/upload-chat-file",
    data,
    headers: { "content-type": "multipart/form-data" },
    ...config,
  });
};

export const getActivitiesServices = (patientId, offset = 0, limit = 20) => {
  return api({
    method: "GET",
    url: socketBaseUrl + "/api/v1/chat/activity/get",
    params: {
      patientId,
      limit,
      offset,
    },
  });
};
