import { Dropdown, Checkbox, Button, Divider } from "antd";
import { SettingOutlined } from "@ant-design/icons";

import { colOptions } from "../../constants";
import { useEffect, useState } from "react";
import { patientTableColsList } from "../../constants";

const DynamicColumns = ({ selectedCols, setSelectedCols }) => {
  const [open, setOpen] = useState(false);
  const [cols, setCols] = useState([]);

  useEffect(() => {
    if (open) {
      setCols(selectedCols);
    }
  }, [open, selectedCols]);

  const onChange = list => {
    setCols(list);
  };

  const handleApply = (event) => {
    event.stopPropagation();
    setSelectedCols(cols);
    localStorage.setItem("patientListCols", JSON.stringify(cols));

    close();
  };

  const handleReset = (event) => {
    event.stopPropagation();
    setSelectedCols(patientTableColsList);
    localStorage.setItem("patientListCols", JSON.stringify(patientTableColsList));
    close();
  };

  const close = () => {
    setOpen(false);
  };

  return (
    <Dropdown
      open={open}
      trigger={["click"]}
      onOpenChange={(flag) => setOpen(flag)} 
      menu={{
        items: [
          {
            label: (
              <div  onClick={(event) => event.stopPropagation()} style={{ minWidth: "250px" }}>
                <Checkbox.Group
                  options={colOptions}
                  value={cols}
                  onChange={onChange}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "10px",
                    gap: "8px",
                  }}
                />

                <Divider style={{ margin: "6px" }} />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button onClick={handleReset} shape="round">
                    Reset
                  </Button>
                  <Button onClick={handleApply} shape="round" type="primary">
                    Apply
                  </Button>
                </div>
              </div>
            ),
            key: "cols",
          },
        ],
      }}
    >
      <div>
        <SettingOutlined onClick={() => setOpen(prev => !prev)} />
      </div>
    </Dropdown>
  );
};

export default DynamicColumns;
