import { Select } from "antd";
import PropTypes from "prop-types";
import styles from "./tableCell.module.scss";
import { UserOutlined } from "@ant-design/icons";
const Assignee = ({ row, staffMembers, handlePatientAssignee }) => {
  return (
    <div className={styles["assignee-wrapper"]}>
      <Select
        optionLabelProp="name"
        showSearch
        dropdownStyle={{ width: "250px" }}
        optionFilterProp="label"
        placeholder={
          <span>
            <UserOutlined style={{ color: "black", marginRight: "4px" }} />
            <span>Unassigned</span>
          </span>
        }
        onChange={(value) => handlePatientAssignee(value, row)}
        value={row?.staffId || null}
        style={{ width: "100%", maxWidth:"250px" }}
        className={row?.staffId ? styles["assignee-ValueWrapper"]: styles["assignee-emptyWrapper"]}
        onClick={(e) => e.stopPropagation()}
        options={[{ label: "Unassign", value: null }, ...staffMembers]}
      />
    </div>
  );
};

Assignee.propTypes = {
  row: PropTypes.object.isRequired,
};

export default Assignee;
