export const calculateProductsTotal = (products) => {
  // console.log("productsproducts", products)
  let totalString = '';
  const totalsByCurrency = {};

  products?.forEach((item) => {
    if (!item.free) {
      const currency = item.currency || 'EUR';
      let productPrice = item.newPrice || 0;  // Use price instead of netPrice
      // console.log("productPriceproductPrice", productPrice)
      // Apply discountAmount if available
      if (item.discountAmount) {
        productPrice = Math.max(productPrice - item.discountAmount, 0);
      } 

   
      // Apply discountPercentage if available
      else if (item.discountPercentage) {
        const discount = (item.discountPercentage / 100) * productPrice;
        productPrice = Math.max(productPrice - discount, 0);
      }
      // console.log("productPriceproductPrice", productPrice)

      const productTotal = productPrice * (item.quantity || 1);
      if (!totalsByCurrency[currency]) {
        totalsByCurrency[currency] = 0;
      }
      totalsByCurrency[currency] += productTotal; 
    }
  });
  totalString = Object.entries(totalsByCurrency)
    .map(([currency, total]) => `${currency} ${total.toFixed(2)}`)
    .join(', ');
  return totalString || '0.00';
};

const calculateDiscountGiven = (product) => {
  const { newPrice, discountAmount, discountPercentage } = product;
  if (discountAmount) {
    return Math.min(discountAmount, newPrice).toFixed(2);
  }
  if (discountPercentage) {
    return ((discountPercentage / 100) * newPrice).toFixed(2); 
  }
  return "0.00"; 
};

export const productsDataMapper = (products) => {
  let data = [];
  if (products) {
    data = products.map((prod, i) => {
      return {
        key: `${i}`,
        labProduct: prod?.productName,
        currency: prod.currency,
        qty: prod?.quantityType === "Per aligner" ? prod?.quantity : "--",
        total: `${
          prod?.free 
            ? `${prod?.currency} 0.00 (Free)` 
            : `${prod?.currency} ${( (prod.quantity || 1) * calculateNetPrice(prod) ).toFixed(2)}`
        }`,
        free: prod.free,
        netPrice:  prod.newPrice -  calculateDiscountGiven(prod),
        price: prod?.newPrice?.toFixed(2),
        discountPrice: priceCalculator(prod.quantity, calculateDiscountGiven(prod)),
        discountAmount: priceCalculator(prod.quantity,prod.discountAmount),
        discountPercentage: prod.discountPercentage, 
        discountGiven : Boolean(prod.discountAmount || prod.discountPercentage)
      };
    });
  }
  return data;
};

function mapAligners(arr) {
  const sortedArr = [...new Set(arr)].sort((a, b) => a - b);

  if (sortedArr.length === 1) {
    return `Aligner ${sortedArr[0]}`; // Single element
  }

  // Check if the array forms a continuous sequence
  const isContinuous = sortedArr.every((num, index) => {
    return index === 0 || num - sortedArr[index - 1] === 1;
  });

  if (isContinuous) {
    return `Aligner ${sortedArr[0]}-${sortedArr[sortedArr.length - 1]}`; // Range
  }
}

export const phasingDataMapper = (phasing) => {
  let data = [];
  if(phasing){
    data = phasing.map((phase,i) => {
      return {
        key: `${i}`,
        phaseNo: phase?.phaseNo,
        alignerIds: mapAligners(phase?.alignerIds),
        deliveryDate: phase?.deliveryDate ? phase?.deliveryDate : '--',
        details: phase?.details
      }
    })
  }
  return data;
}

export const initialProductsFormData = () => {
  return [
    {
      labProductId: null,
      arches: null,
      price: "",
      quantity: null,
      total: 0,
      free: false,
    },
  ];
};

const calculateDiscountPrice = (product) => {
  const { newPrice, discountAmount, discountPercentage } = product;
  if (discountAmount) {
    return Math.max(newPrice - discountAmount, 0);
  }
  if (discountPercentage) {
    const discount = (discountPercentage / 100) * newPrice;
    return Math.max(newPrice - discount, 0);
  }
  return 0;
};


const calculateNetPrice = (product) => {
  const { newPrice, discountAmount, discountPercentage } = product;

  if (discountAmount) {
    return Math.max(newPrice - discountAmount, 0);
  }

  if (discountPercentage) {
    const discount = (discountPercentage / 100) * newPrice;
    return Math.max(newPrice - discount, 0); 
  }


  return newPrice;
};

export const labProductsFormMapper = (products) => {
 
  let data = [];
  if (products) {
    data = products.map((prod, i) => {
      return {
        id: prod.id,
        labProductId: prod.labProductId,
        arches: prod.arches,
        labProduct: prod.productName,
        quantity: prod.quantity || 1,
        price: prod.netPrice,
        quantityType: prod.quantityType,
        total: prod.netPrice,
        free: prod.free,
        currency: prod.currency,
        discountAmount: prod.discountAmount,
        discountPercentage: prod.discountPercentage,
        discountPrice: calculateDiscountPrice(prod),
        netPrice:  calculateNetPrice(prod),
        newPrice: prod.newPrice
      };
    });
  }
  return data;
};

const priceCalculator = (quantity, amount) => {
  const qty = quantity || 1;
  if (!amount) return "0.00";
  return (qty * amount).toFixed(2); 
};

const productMapper = (product) => {
  // console.log("productttt", product)
  const netPrice = product.newPrice -  calculateDiscountGiven(product)
  return {
    productName: product.productName,
    arches: product.arches,
    currency: product.currency,
    free: product?.free,
    quantity: product.quantityType === "Per aligner" ? product.quantity : "--",
    price: product?.free
      ? product.currency + " " + "0.00 (Free)"
      : product.newPrice
      ? `${product.currency} ${netPrice.toFixed(2)}`
      : "--",
      total: `${
        product?.free 
          ? `${product?.currency} 0.00 (Free)` 
          : `${product?.currency} ${( (product.quantity || 1) * calculateNetPrice(product) ).toFixed(2)}`
      }`,
      productPrice:  product.newPrice ,
      discountPrice: calculateDiscountGiven(product) ,
    //   discountAmount: calculateDiscountGiven(product),
    //   discountPercentage: product.discountPercentage,
      discountGiven : Boolean(product.discountAmount || product.discountPercentage),
     netPrice:  netPrice,


  };
};

export const orderDetailMapper = (orderDetails) => {
  return {
    productList: orderDetails?.labProductList?.length
      ? orderDetails.labProductList.map((product) => productMapper(product))
      : [
          {
            productName: "--",
            arches: "--",
            currency: "--",
            free: "--",
            quantity: "--",
            price: 0,
            total: "--",
          },
        ],
    payment_status: orderDetails?.payment_status,
    orderNote: orderDetails?.orderNote ? orderDetails?.orderNote : "--",
    shipping_address: orderDetails?.shipping_address,
    order_status: orderDetails?.order_status,
    totalPrice: calculateProductsTotal(orderDetails?.labProductList),
    orderScanFiles: orderDetails?.orderScanFiles

  };
};

export function getProductServiceName(dataObj) {
  const labProductList = dataObj?.labProductList;

  if (!labProductList || labProductList.length === 0) {
    return "Not Available";
  }

  if (labProductList.length === 1) {
    return labProductList[0].productName;
  }

  return `${labProductList[0].productName}, +${labProductList.length - 1}`;
}

export function getProductServiceTotalQuantity(dataObj) {
  const labProductList = dataObj?.labProductList;

  if (!labProductList || labProductList.length === 0) {
    return "Not Available"; // Return 0 if there are no products
  }

  return labProductList.reduce((total, product) => {
    return total + (product.quantity || 0); // Add the quantity of each product to the total
  }, 0);
}

export function getProductServiceNames(dataObj) {
  const labProductList = dataObj?.labProductList;

  if (!labProductList || labProductList.length === 0) {
    return "";
  }

  return dataObj.labProductList.map((product) => product.productName).join(", ");
}


export const calculateFormDiscountPrice = (value, productPrice) => {
  if (!value || productPrice <= value) {
    return '0.00';
  }
  return (productPrice - value).toFixed(2);
};



export const initialPhasesFormData = () => {
  return [
    {
      // aligners: true,
      aligners:null,
    },
  ];
};

export const intialPhasingFormData = () => {
  return [
    {
      alignerIds: [],
      deliveryDate: '',
      details: '',
      batch: true
    },
    {
      alignerIds: [],
      deliveryDate: '',
      details: '',
      batch: false
    }
  ]
}