import { SMILE } from "constants";
import { TOP_TEETH } from "constants";
import { BOTTOM_TEETH } from "constants";
import { SIDE } from "constants";
import { FACE_NO_EXPRESSION } from "constants";
import {
  PROGRESS_IMAGES_TITLES_ORDER,
  PROGRESS_IMAGES_ALIGNER_ORDER,
  PROGRESS_IMAGES_ORDER
} from "constants";

export function sortImagesByOrder(images, imageStageTypeKeys) {
  // Define the order of image types
  let order = PROGRESS_IMAGES_TITLES_ORDER;
  if (imageStageTypeKeys) {
    if (imageStageTypeKeys[0] !== "100") {
      order = PROGRESS_IMAGES_ALIGNER_ORDER;
    }
  }
  // Separate images into two arrays: those in the order and those not in the order
  const imagesInOrder = [];
  const otherImages = [];

  images.forEach((image) => {
    if (order.includes(image.imageViewType)) {
      imagesInOrder.push(image);
    } else {
      otherImages.push(image);
    }
  });

  // Sort the images in the order array based on the predefined order
  imagesInOrder.sort((a, b) => {
    return order.indexOf(a.imageViewType) - order.indexOf(b.imageViewType);
  });

  // Combine the sorted images in order with the other images
  const sortedImages = [...imagesInOrder, ...otherImages];
  return sortedImages;
}

export function formatImageView(imageView) {
  //  console.log("imageViewimageViewimageView", imageView)
  if (imageView === FACE_NO_EXPRESSION) {
    return "Face - No Expression"
  } else if(imageView === SMILE){
   return "Face - Smiling"
  }
  else if(imageView === SIDE){
    return "Side (Any Right or Left)"
   }
   else if(imageView === TOP_TEETH){
    return "Upper Teeth"
   }
   else if(imageView === BOTTOM_TEETH){
    return "Lower Teeth"
   }
  else{
    return imageView.replace(/([A-Z])/g, " $1").trim();
  }
}

function containsAlignerImages(data) {
  return data.some(item => item.patient_image_view === "CurrentAlignerFrontSide" || item.imageViewType === "CurrentAlignerFrontSide");
}

export const sortPhotosByOrder = (photos) => {
  const alignerCheck = containsAlignerImages(photos) 
  const order = alignerCheck ? PROGRESS_IMAGES_ALIGNER_ORDER : PROGRESS_IMAGES_ORDER; 
  const photosInOrder = [];
  const otherPhotos = [];

  photos.forEach((photo) => {
    if (
      order.includes(photo.patient_image_view) ||
      order.includes(photo.imageViewType)
    ) {
      photosInOrder.push(photo);
    } else {
      otherPhotos.push(photo);
    }
  });

  // Sort the photos in the order array based on the predefined order
  photosInOrder.sort((a, b) => {
    const aType = a.patient_image_view || a.imageViewType;
    const bType = b.patient_image_view || b.imageViewType;
    return order.indexOf(aType) - order.indexOf(bType);
  });

  // Combine the sorted photos in order with the other photos
  const sortedPhotos = [...photosInOrder, ...otherPhotos];
  return sortedPhotos;
};
