import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col } from "antd";
import dayjs from "dayjs";
import { ReactComponent as PhaseIcon } from "assets/images/PhaseIcon.svg";
import Box from "../../Box/Box";
import { getWeeklyPhasingOrders } from "api/treatment";
import styles from "./phasing.module.scss";

const TodayPhasing = ({ labId, isFuture }) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [duePhase, setDuePhases] = useState(0);

  useEffect(() => {
    if (labId) {
      (async () => {
        try {
          setIsLoading(true);
          const response = await getWeeklyPhasingOrders(
            labId,
            dayjs().format("YYYY-MM-DD"),
            dayjs().format("YYYY-MM-DD"),
          );
          const { data } = response.data;

          // console.log(data);
          const values = Object.values(data);
          setDuePhases(values[0]);
        } catch (err) {
          console.log("err", err);
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [isFuture, labId]);

  const onClick = () => {
    const current = dayjs().format("YYYY-MM-DD");

    const queryParams = new URLSearchParams({
      column: "phasingDate",
      startDate: current,
      endDate: current,
    }).toString();

    history.push(`/lab/patients?${queryParams}`);
  };

  const displayPhasingOrders = Number(duePhase) > 0 ? Number(duePhase) : 0;

  return (
    <Box hoverable isLoading={isLoading}>
      <div
        onClick={onClick}
        className={styles["phases-info-container"]}
      >
        <h1>Today's Phasing Orders</h1>
        <Row align="middle" gap={[16, 16]}>
          <Col>
            <PhaseIcon />
          </Col>
          <Col>
            <div style={{ textAlign: "start", marginLeft: "10px" }}>
              <h2>{displayPhasingOrders}</h2>
              <p style={{ color: displayPhasingOrders > 0 ? "#FF5454" : "" }}>
                Due Today
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </Box>
  );
};

export default TodayPhasing;
