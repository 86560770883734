import { Modal, message } from "antd";
import { primaryColor } from "colors-config";
import { CloseCircleFilled } from '@ant-design/icons';

const errorModal = (content, onOk) => {
  Modal.confirm(
  { 
    title: "Oops! Error", 
    icon: <CloseCircleFilled style={{ color: '#FF0000'}} />, 
    content, 
    onOk, 
    onCancel() { 
      window.location.href = process.env.REACT_APP_CLINIC_LOGIN_URL;
    } , 
    centered: true, 
    cancelText: 'Go to Clinician Portal',
    cancelButtonProps: {
      style: {
        borderRadius: '30px'
      },
    },   
    okButtonProps: {
      style: {
        width: "5rem",
        background: primaryColor,  // Custom background color
        borderColor: primaryColor,  // Custom border color
        color: '#fff',  // Custom text color,
        borderRadius: '30px'
      },
    }
  });
};

export default errorModal;
