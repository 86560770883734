import { ALIGNER_COLOR_CONFIGURATION } from "constants";

export function uniqByKeepLast(data, key) {
  return [...new Map(data.map((x) => [key(x), x])).values()];
}

export const dataMapper = (iprData) => {
  let upper = [];
  const lower = [];
  iprData.forEach((el) => {
    const mappedIprObject = {
      ...el,
      name: `aligner-${el.aligner_count}`,
      session: el.aligner_count,
      uniqueId: el.id,
    };
    if (el.jawType === "upper") {
      upper.push(mappedIprObject);
    } else {
      lower.push(mappedIprObject);
    }
  });

  const upperASC = upper.sort((a, b) => {
    return a.session - b.session;
  });
  return {
    lower: lower,
    upper: upper,
  };
};

export const validateTreatmentPlan = (values) => {
  const { lowerAlignersNo, upperAlignersNo } = values;
  if (lowerAlignersNo === 0 && upperAlignersNo === 0) {
    return false;
  }
  return true;
};


export function generateAlignerLabels(alignerLabel, noOfUpper, noOfLower) {
  return alignerLabel.map((obj, index) => {
      obj.checked = true;
      obj.session = index + 1;
      if ((obj.aligner_count <= noOfUpper) && (obj.aligner_count <= noOfLower)) {
          obj.sessionListName = `U${obj.aligner_count}L${obj.aligner_count}`;
      } else if (obj.aligner_count > noOfLower) {
          obj.sessionListName = `U${obj.aligner_count}`;
      } else if (noOfLower > noOfUpper) {
          obj.sessionListName = `L${obj.aligner_count}`;
      }
      return obj;
  });
}

export const iprJawMappers = (data, treatment) => {
  let apiIprData = data;
  if (treatment.revision_plan_version) {
    apiIprData = apiIprData.filter(
      (obj) => obj.refinement && treatment.lab_treatment_id == obj.labTreatmentId
    );
  } else {
    apiIprData = apiIprData.filter((obj) => !obj.refinement);
  }
  const { lower, upper } = dataMapper(apiIprData);
  const upperJaw = upper.reverse();

  const lowerJaw = lower;

  const iprData = apiIprData;
  iprData.sort((a, b) => {
    return a.aligner_count - b.aligner_count;
  });
  let uniqueAlignerLabel = uniqByKeepLast(iprData, (obj) => obj.aligner_count);

  const alignerLabel = generateAlignerLabels(uniqueAlignerLabel, treatment.num_of_upper_aligners, treatment.num_of_lower_aligners);

  return {
    upperJaw,
    lowerJaw,
    alignerLabel,
  };
};


export function getAlignerClassName(aligner, upperAligner, alignerType, upperIndex, alignersArray) {
  let className = `aligner-box aligner-box${alignerType}`;

  if (alignerType === "upper") {
      if (aligner.value && upperIndex === alignersArray.length - 1) {
          className += ` aligner-0-upper`;
      }
  } else {
      if (aligner.value) {
          className += ` aligner-${upperIndex}-${alignerType}`;
      }
  }

  return className.trim();
}


export function getAlignerBorder(aligner, upperAligner) {
  return ALIGNER_COLOR_CONFIGURATION[upperAligner.aligner_count - 1]
}

export function getAlignerBackground(aligner, upperAligner) {
  let bg = "#0000";
 if (aligner.value) {
   bg = ALIGNER_COLOR_CONFIGURATION[upperAligner.aligner_count - 1]
 }
 return bg
}


export function getAlignerNumberBackground(aligner) {
  let bg = "#fff";
 if (aligner) {
   bg = ALIGNER_COLOR_CONFIGURATION[aligner - 1]
 }
 return bg
}


export const hasAttachmentType = (attachments, attachmentTypeToCheck) => {
 for (let attachment of attachments) {
  for (let aligner of attachment.alignerValues) {
    if (aligner.attachmentType === attachmentTypeToCheck) {
      return true; 
    }
  }
}
return false; 
}

export const initialTreatmentTypesFormData = (types) => {
  if (types.length) {
    return [
      {
        smilelignTreatmentTypeId: types[0].id,
      },
    ];
  }
  else{
    return [
      {
        smilelignTreatmentTypeId: null,
      },
    ];
  }
}

