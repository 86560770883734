import { useState, useEffect, useRef } from "react";
import { Row, Col, Divider, Button, Spin, message } from "antd";
import classNames from "classnames";
import ScanModel from "./components/ScanModel/ScanModel";
import ScanHistory from "./components/ScanHistory/ScanHistory";
import styles from "./scans.module.scss";
import { primaryColor } from "colors-config";
import FolderIcon from "components/shared/Icon/FolderIcon";
import { ReactComponent as AddIcon } from "assets/images/teeths/addIcon.svg";
import { getPrescriptionScanFiles } from "api/prescription";
import { deletePrescriptionScanFile } from "api/prescription";
import ScansView from "./components/ScansView/ScansView";
import { addConfirmScanFilesAPI } from "api/scans";
import dayjs from 'dayjs';

const ClinicScans = ({ patientId, clinicId }) => {
  const [fileLoader, setFileLoader] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showScanFiles, setShowScanFiles] = useState(false);

  const [scansInfo, setScansInfo] = useState({
    uploadedBy: "",
    uploadedDate: "",
    scanType: "",
  });
  const treatmentType = "prescription";

  const [uploadedFiles, setUploadedFiles] = useState([]);

  const initializeUploadedFiles = data => {
    const initialFiles = {
      UpperArch: null,
      LowerArch: null,
      BiteScan: null,
      BiteScan2: null,
    };
    data?.forEach(file => {
      if (initialFiles.hasOwnProperty(file.patient_image_view)) {
        initialFiles[file.patient_image_view] = file;
      }
    });

    return initialFiles;
  };
  const updateUploadedFiles = (newFile, existingFiles) => {
    const updatedFiles = { ...existingFiles };
    const formattedKey = newFile.patient_image_view;
    if (updatedFiles.hasOwnProperty(formattedKey)) {
      updatedFiles[formattedKey] = {
        ...newFile,
        uploadedFrom: newFile.createdFrom,
        uploadDate: newFile.createdAt,
      };
    }

    return updatedFiles;
  };

  

  const initializeScansInfo = data => {
    if (data.length === 0)
      return { uploadedBy: "", uploadedDate: "", scanType: "" };

    const latestUpload = data[data.length - 1];

    return {
      uploadedBy: latestUpload?.createdFrom || "",
      uploadedDate: latestUpload?.upload_date ? dayjs(latestUpload?.upload_date).format("DD-MMM-YYYY"):"" ,
      scanType: latestUpload?.model || "",
    };
  };

  useEffect(() => {
    const fetchPrescriptionData = async () => {
      if (patientId) {
        try {
          setLoading(true);
          const { data } = await getPrescriptionScanFiles(patientId);
          const finalRes = initializeUploadedFiles(data.data);
          const scanInfo = initializeScansInfo(data.data);
          setUploadedFiles(finalRes);
          setScansInfo(scanInfo);
        } catch (error) {
          console.error("Error fetching prescription data:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchPrescriptionData();
  }, [patientId,]);

  const handleFileUpload = (file, type) => {
    // setUploadedFiles({
    //   ...uploadedFiles,
    //   [file.patient_image_view]: { file, treatment: treatmentType },
    // });
  };

  const handleFileRemove = type => {
    const formattedKey = type;
    setUploadedFiles({ ...uploadedFiles, [formattedKey]: null });
  };

  const handleFileSubmission = files => {
    // console.log("Uploaded files:", files);
  };

  const deleteScanFile = (file, existingFiles) => {
    const updatedFiles = { ...existingFiles };
    const keyToDelete = file.patient_image_view;
    if (updatedFiles.hasOwnProperty(keyToDelete)) {
      updatedFiles[keyToDelete] = null;
    }

    return updatedFiles;
  };

  const handleRemove = async scan => {
    try {
      setLoading(true);
      const payload = {
        patientId: patientId,
        imageView: scan.patient_image_view,
      };
      const res = await deletePrescriptionScanFile(payload, clinicId);
      const updatedFiles = deleteScanFile(scan, uploadedFiles);
      setUploadedFiles(updatedFiles);
      message.success("Scan deleted successfully.");
    } catch (error) {
      console.error("Error deleting data:", error);
    } finally {
      setLoading(false);
    }
  };
  
  const lockRef = useRef(false);

  const uploadConfirm = async (addNewData) => {
    if (lockRef.current) {
      while (lockRef.current) {
        await new Promise((resolve) => setTimeout(resolve, 50)); 
      }
    }
  
    lockRef.current = true; 
    try {

      const { data } = await addConfirmScanFilesAPI(clinicId, addNewData);
        setUploadedFiles((prevUploadedFiles) => {
          const finalRes = updateUploadedFiles(data, prevUploadedFiles);
          return finalRes;
        });

        const scanInfo = {
          uploadedBy: "lab",
          uploadedDate: dayjs().format('YYYY-MM-DD'),
          scanType: "prescription",
        }
        setScansInfo(scanInfo);
    } finally {
      lockRef.current = false; 
    }
  };


  return (
    <div className={classNames(styles["scans-col-container"])}>
      <Spin spinning={loading}>
        <div className={classNames(styles["scans-col-header"])}>
          <div className={classNames(styles["scans-col-headings"])}>
            <h1>
              Scan Files
              {scansInfo.scanType && (
                <span
                  style={{ color: primaryColor }}
                >{` (${scansInfo.scanType})`}</span>
              )}
            </h1>
            <h2>
              {scansInfo.uploadedDate && (
                <span>{`Updated: (${scansInfo.uploadedDate})`}</span>
              )}
            </h2>
          </div>
          <Button
            icon={<AddIcon style={{ fill: primaryColor }} />}
            shape="round"
            onClick={() => setIsModalOpen(true)}
            className={classNames(styles["new-btn"])}
          >
            New Scans
          </Button>
        </div>

        <Divider style={{ margin: "10px 0px" }} />
        <div style={{ padding: "0px 20px", width: "60%" }}>
            <ScansView
              uploadedFiles={uploadedFiles}
              treatmentType={treatmentType}
              handleFileUpload={handleFileUpload}
              handleRemove={handleRemove}
              setFileLoader={setFileLoader}
              clinicId={clinicId}
              patientId={patientId}
              setUploadedFiles={setUploadedFiles}
              setScansInfo={setScansInfo}
              uploadConfirm={uploadConfirm}
            />
        </div>
        <Divider style={{ margin: "20px 0px 10px 0px" }} />
        <div className={classNames(styles["history-btn"])}>
          <span onClick={() => setShowHistoryModal(true)}>
            <FolderIcon /> See History
          </span>
        </div>
        {isModalOpen && (
          <ScanModel
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            onSubmit={handleFileSubmission}
            patientId={patientId}
            clinicId={clinicId}
            setFiles={setUploadedFiles}
            setShowScanFiles={setShowScanFiles}
            setScansInfo={setScansInfo}
          />
        )}

        {showHistoryModal && (
          <ScanHistory
            patientId={patientId}
            showHistoryModal={showHistoryModal}
            setShowHistoryModal={setShowHistoryModal}
            clinicId={clinicId}
          />
        )}
      </Spin>
    </div>
  );
};

export default ClinicScans;
