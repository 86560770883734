import api from "./api";
const baseUrl = process.env.REACT_APP_CLINIC_BASE_URL;

export const downloadImage = fileName => {
  return baseUrl + "/clinic/progress-image/download/" + fileName;
};
export const getLabProductList = labId => {
  return api({
    method: "GET",
    url: `/lab/product/list/${labId}`,
  });
};

export const getPatientList = (params) => {
  return api({
    method: "GET",
    url: "/lab/lab/patientList",
    params: params,
  });
};

export const archivePatient = data => {
  return api({
    method: "POST",
    url: "/lab/archivePatient",
    data: data,
  });
};

export const unArchivePatient = data => {
  return api({
    method: "PUT",
    url: "/patient/unArchivePatient",
    data: data,
  });
};

export const getArchivePatientList = params => {
  return api({
    method: "GET",
    url: "/lab/archivePatientList",
    params: params,
  });
};

export const updateLabOrderStatus = data => {
  return api({
    method: "POST",
    url: "/lab/lab/patientlist/orders/updateStatus",
    data: data,
  }).then(obj => obj.data);
};

export const updatePhaseOrderStatuses = data => {
  return api({
    method: "PUT",
    url: "/lab/orders/update-phase-order",
    data,
  });
};

export const updateLabOrderDispatchStatus = data => {
  return api({
    method: "POST",
    url: "/lab/patientlist/orders/updateDispatchDate",
    data: data,
  }).then(obj => obj.data);
};

export const updateLabOrderTrackingStatus = data => {
  return api({
    method: "POST",
    url: "/lab/lab/patientlist/orders/updateTracking",
    data: data,
  });
};

export const getAllTreatmentPlans = model => {
  return api({
    method: "GET",
    url: `/lab/lab/patientlist/treatmentSetup/getOld?patientId=${model.patientId}&caseId=${model.caseId}`,
  });
};

export const getResource = labId => {
  return api({
    method: "GET",
    url: `/clinic/labPartner/getResource?labId=${labId}`,
  });
};

export const labFormResources = labId => {
  return api({
    method: "GET",
    url: `/lab/getResource?labId=${labId}`,
  });
};

export const deleteFormResource = data => {
  return api({
    method: "delete",
    url: "/lab/deleteResource",
    data,
  });
};

export const labResourceConfirm = data => {
  return api({
    method: "POST",
    url: "/lab/resource/upload/confirm",
    data,
  });
};

export const getCurrencies = clinicId => {
  return api({
    method: "GET",
    url: `/public/currencies`,
  });
};

export const getProducts = labId => {
  return api({
    method: "GET",
    url: `/lab/product/list/${labId}`,
  });
};

export const getAttachmentProducts = labId => {
  return api({
    method: "GET",
    url: `/lab/attachment/list/${labId}`,
  });
};

export const addLab = data => {
  return api({
    method: "POST",
    url: `/lab/lab/addLab`,
    data: data,
  });
};

export const saveProducts = (data, labId) => {
  return api({
    method: "POST",
    url: `/lab/product/add/${labId}`,
    data: data,
  });
};

export const getLabOrdersList = params => {
  return api({
    method: "GET",
    url: `/lab/lab/orders`,
    params: params,
  }).then(res => res.data);
};

export const getProductsName = labId => {
  return api({
    method: "GET",
    url: `/lab/product/name/list/${labId}`,
  });
};

export const updateCommonLabStatusAPI = data => {
  return api({
    method: "POST",
    url: `/lab/patientlist/orders/updateStatus`,
    data: data,
  }).then(res => res.data);
};

export const autoPopulateLabDetailsOnFirstLogin = params => {
  return api({
    method: "GET",
    url: `/lab/autoPopulateLabDetailsOnFirstLogin`,
    params,
  });
};
export const getLabOrdersAPI = params => {
  return api({
    method: "GET",
    url: `/lab/lab/patientlist/orders/get`,

    params,
  }).then(res => res.data);
};

export const getOnBoardingClinics = data => {
  return api({
    method: "GET",
    url: `/lab/clinic-on-boarding/get`,
    params: data,
  }).then(res => res.data);
};

export const getPartneredClinics = data => {
  return api({
    method: "GET",
    url: "/lab/lab/clinicList",
    params: {
      ...data,
      limit: data.pageSize,
    },
  }).then(res => {
    const response = res?.data?.body;
    const data = {
      items: response.data,
      totalItems: response.total_records,
    };
    return {
      data,
    };
  });
};

export const saveOnBoardingClinic = data => {
  return api({
    method: "POST",
    url: `/lab/clinic-on-boarding/add`,
    data: data,
  });
};

export const editOnBoardingClinic = data => {
  return api({
    method: "PUT",
    url: `/lab/clinic-on-boarding/update`,
    data: data,
  });
};

export const deleteOnBoardingClinic = data => {
  return api({
    method: "DELETE",
    url: `/lab/clinic-on-boarding/delete`,
    data: data,
  });
};

export const checkClinicEmailExists = email => {
  return api({
    method: "POST",
    url: `/public/checkClinicEmailExists`,
    params: { email },
  }).then(res => res.data);
};

export const saveOnBoardingClinicFile = (data, config) => {
  return api({
    method: "POST",
    url: `/lab/clinic-on-boarding/upload`,
    data: data,
    ...config,
  });
};

export const downloadOnBoardingErrorFile = data => {
  return api({
    method: "POST",
    url: `/lab/clinic-on-boarding/download/invalid-file`,
    data: data,
  });
};

export const deleteBulkOnBoardingClinic = data => {
  return api({
    method: "DELETE",
    url: `/lab/clinic-on-boarding/bulk-delete`,
    data: data,
  });
};

export const resendEmailBulkOnBoardingClinic = data => {
  return api({
    method: "POST",
    url: `/lab/clinic-on-boarding/resend-email`,
    data: data,
  });
};

export const addTempLabRegistrationApi = data => {
  return api({
    method: "POST",
    url: `/lab/addTempLabRegistration`,
    data: data,
  });
};

export const getDashboardAnalytics = (labId, data) => {
  return api({
    method: "GET",
    url: `/lab/dashboard/analytics/${labId}`,
    params: data,
  }).then(res => res.data);
};
export const checkCognitoUserAPI = email => {
  return api({
    method: "GET",
    url: `/lab/auth/check-cognito-user`,
    params: {
      email,
    },
  });
};

export const uploadAppMediaApi = data => {
  return api({
    method: "POST",
    url: "/media/upload",
    data,
    headers: { "content-type": "multipart/form-data" },
  });
};

export const firstLoginCheck = (labEmail, token) => {
  return api({
    method: "GET",
    url: `/lab/checkClinicFirstLogin`,
    params: {
      labEmail,
    },
    headers: {
      authorization: token,
    },
  });
};

export const userResetPassword = ({ email }) => {
  return api({
    method: "GET",
    url: `/lab/auth/userResetPassword/${email}`,
  });
};
