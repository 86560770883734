import { memo , useState} from "react";
import { Form, Row, Col, Radio, Select } from "antd";
import { FORM_CONSTANTS } from "../../constants";
const { Option } = Select;
const Treatment = ({ onFormChange, disabled, prescriptionForm, title}) => {
  const [disableOpenBite,setDisableOpenBite] = useState(true)
  const [disableCrossBite,setDisableCrossBite] = useState(true)

  // const handleCrossChange = (value) => {
  //   // console.log(`selected ${value}`);
  //   setCrossBiteValue(value);
  // };

  // const handleOpenChange = (value) => {
  //   setOpenBiteValue(value);
  // };
  const handleCrossChange = (value, fieldName, dropDownVal, setter) => {
    let val = false;
    onFormChange(fieldName, value);
    if (value !== FORM_CONSTANTS.AS_NEEDED) {
      val = true;
      prescriptionForm.setFieldsValue({ [dropDownVal]: null });
    }
    setter(val);
  };

  return (
    <div>
      <h1 className="form-heading" style={{ margin: "30px 0px 28px 0px" }}>
   {title}
      </h1>
      <div className="instruction-row">
        <Row>
          <Col lg={6}>
            <label>Overjet</label>
          </Col>
          <Col lg={18}>
            <Form.Item name="overjet" label="">
              <Radio.Group onChange={(e) => onFormChange("overjet", e.target.value)} disabled={disabled}>
                <Radio value={FORM_CONSTANTS.MAINTAIN}>Maintain</Radio>
                <Radio value={FORM_CONSTANTS.IMPROVE}>Improve</Radio>
                <Radio value={FORM_CONSTANTS.AS_NEEDED}>As needed</Radio>
                <Radio value={FORM_CONSTANTS.N_A}>N/A</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </div>
      <div className="instruction-row">
        <Row>
          <Col lg={6}>
            <label>Overbite</label>
          </Col>
          <Col lg={18}>
            <Form.Item name="overbite" label="">
              <Radio.Group onChange={(e) => onFormChange("overbite", e.target.value)} disabled={disabled}>
              <Radio value={FORM_CONSTANTS.MAINTAIN}>Maintain</Radio>
              <Radio value={FORM_CONSTANTS.IMPROVE}>Improve</Radio>
              <Radio value={FORM_CONSTANTS.AS_NEEDED}>As needed</Radio>
              <Radio value={FORM_CONSTANTS.N_A}>N/A</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </div>
      <div className="bite-row">
        <Row>
          <Col lg={6}>
            <label>Openbite</label>
          </Col>
          <Col lg={18}>
            <Form.Item name="openbite" label="">
              <Radio.Group onChange={(e) =>
                  handleCrossChange(
                    e.target.value,
                    "openbite",
                    "openBiteValue",
                    setDisableOpenBite
                  )
                } disabled={disabled}>
              <Radio value={FORM_CONSTANTS.MAINTAIN}>Maintain</Radio>
                <Radio value={FORM_CONSTANTS.IMPROVE}>Improve</Radio>
                <div className="bite-select">
                <Radio value={FORM_CONSTANTS.AS_NEEDED}></Radio>
                <Form.Item name="openBiteValue" label="">
                <Select
                 disabled={disabled || disableOpenBite}
                    placeholder="Select Option"
                    onChange={(val) => onFormChange("openBiteValue", val)}
                    style={{width:100}}
                  >
                    <Option value="Anterior">Anterior</Option>
                    <Option value="Posterior">Posterior</Option>
                  </Select>
                </Form.Item>
 
                </div>
                <Radio value={FORM_CONSTANTS.N_A}>N/A</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </div>
      <div className="bite-row">
        <Row>
          <Col lg={6}>
            <label>Crossbite</label>
          </Col>
          <Col lg={18}>
            <Form.Item name="crossbite" label="">
              <Radio.Group onChange={(e) =>
                  handleCrossChange(
                    e.target.value,
                    "crossbite",
                    "crossBiteValue",
                    setDisableCrossBite
                  )
                } disabled={disabled}>
              <Radio value={FORM_CONSTANTS.MAINTAIN}>Maintain</Radio>
              <Radio value={FORM_CONSTANTS.IMPROVE}>Improve</Radio>
                <div className="bite-select">
                <Radio value={FORM_CONSTANTS.AS_NEEDED}></Radio>
                <Form.Item name="crossBiteValue" label="">
                <Select
                    disabled={disabled || disableCrossBite}
                    placeholder="Select Option"
                    onChange={(val) => onFormChange("crossBiteValue", val)}
                    style={{width:100}}
                  >
                    <Option value="Anterior">Anterior</Option>
                    <Option value="Posterior">Posterior</Option>
                  </Select>
                </Form.Item>
                
                </div>
                <Radio value={FORM_CONSTANTS.N_A}>N/A</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </div>

      <div className="instruction-row">
        <Row>
          <Col lg={6}>
            <label>Midline</label>
          </Col>
          <Col lg={18}>
            <Form.Item name="midline" label="">
              <Radio.Group onChange={(e) => onFormChange("midline", e.target.value)} disabled={disabled}>
              <Radio value={FORM_CONSTANTS.MAINTAIN}>Maintain</Radio>
              <Radio value={FORM_CONSTANTS.IMPROVE}>Improve</Radio>
              <Radio value={FORM_CONSTANTS.AS_NEEDED}>As needed</Radio>
              <Radio value={FORM_CONSTANTS.N_A}>N/A</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default memo(Treatment);
