import React, { useState, useEffect } from "react";
import IPR from "./IPR";
import { useLocation } from "react-router-dom";

const IprFrame = ({ iprData, chartValue , hideTeethParam}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [upperAligners, setUpperAligners] = useState([]);
  const [lowerAligners, setLowerAligners] = useState([]);

  const dataMapper = iprData => {
    let upper = [];
    const lower = [];
    iprData.forEach(el => {
      const mappedIprObject = {
        ...el,
        name: `aligner-${el.aligner_count}`,
        session: el.aligner_count,
        uniqueId: el.id,
      };
      if (el.jawType === "upper") {
        upper.push(mappedIprObject);
      } else {
        lower.push(mappedIprObject);
      }
    });

    const upperASC = upper.sort((a, b) => {
      return a.session - b.session;
    });
    return {
      lower: lower,
      upper: upper,
    };
  };

  const filterAttachmentsByAlignerCount = (data, excludeAlignerCounts) => {
    if (!Array.isArray(data) || !Array.isArray(excludeAlignerCounts)) {
      console.error(
        "Invalid input: Both data and excludeAlignerCounts should be arrays."
      );
      return [];
    }
    return data.filter(
      item => !excludeAlignerCounts.includes(item.aligner_count)
    );
  };

  useEffect(() => {
    if (iprData) {
      // console.log("iprData:", iprData);
      const excludeAlignerCountsParam = queryParams.get("alignerCounts");
      const excludeAlignerCounts = excludeAlignerCountsParam
        ? excludeAlignerCountsParam.split(",").map(Number)
        : [];
      // console.log("excludeAlignerCountsexcludeAlignerCounts", excludeAlignerCounts)
      const filteredData = filterAttachmentsByAlignerCount(
        iprData,
        excludeAlignerCounts
      );
      // console.log("Filtered Data:", filteredData);

      const { lower, upper } = dataMapper(filteredData);
      const upperJaw = upper.reverse();
      const lowerJaw = lower;
      //  console.log("upperJaw:", upperJaw);
      //  console.log("lowerJaw:", lowerJaw);
      setLowerAligners(lowerJaw);
      setUpperAligners(upperJaw);
    }
  }, [iprData]);

  return (
    <div>
      <IPR
        chartValue={chartValue}
        lowerAligners={lowerAligners}
        upperAligners={upperAligners}
        hideTeethParam={hideTeethParam}
      />
    </div>
  );
};

export default IprFrame;
