import { useEffect, useState } from "react";
import { Modal, Divider } from "antd";

import AppFileList from "../../../shared/AppFileList/AppFileList";
import { downloadImage } from "api/lab";

import { getFormatDate } from "./utils";
import { scansArray } from "utils/Patient.js";
import styles from "./treatmentSetup.module.scss";
import { stlFilesOrder } from "utils/Lab";
import { SCAN_FILE_TYPES } from "constants";

const RequestRefinementModal = ({
  refinementModalOpen,
  setRefinementModalOpen,
  refinementData,
  setShowTreatmentSetupModal,
  footerCheck,
}) => {
  const [fileList, setFileList] = useState({
    clinicalFiles: [],
    scanFiles: [],
  });

  useEffect(() => {
    if (refinementData?.patient_media?.length) {
      const scanFiles = [];
      const clinicalFiles = [];
      refinementData.patient_media.forEach((media) => {
        const fileNameWithoutExtension = media.name.split(".")[0];

        if (SCAN_FILE_TYPES.includes(fileNameWithoutExtension)) {
          const name = media.name.split(".")[0];

          const scanItem = scansArray.find((scan) => scan.name === name);
          if (scanItem || name==='biteScan') {
            scanFiles.push(media);
          } else {
            clinicalFiles.push(media);
          }
        } else {
          clinicalFiles.push(media);
        }
      });

      const sortedScanFiles = scanFiles?.sort((a, b) => stlFilesOrder(a) - stlFilesOrder(b)) || [];

      setFileList({
        scanFiles: sortedScanFiles,
        clinicalFiles,
      });
    }
  }, [refinementData]);

  const handleOk = () => {
    handleCancel();
    setShowTreatmentSetupModal(true);
  };
  const handleCancel = () => {
    setRefinementModalOpen(false);
  };

  const onDownloadClick = async (image) => {
    try {
      const imageName = image.hash + "." + image.meta.extension;
      window.open(downloadImage(imageName));
    } catch (error) {}
  };

  const onPreviewClick = (image) => {
    const link = document.createElement("a");
    link.href = image.url;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
  };

  const onDownloadAllClick = () => {
    refinementData.patient_media.map((image) => {
      try {
        const imageName = image.hash + "." + image.meta.extension;
        window.open(downloadImage(imageName));
      } catch (error) {}
    });
  };
  return (
    <>
      <Modal
        open={refinementModalOpen}
        width={750}
        title="Refinement Details"
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Update Refinement"
        okButtonProps={{
          className: "app-button-class",
        }}
        cancelButtonProps={{
          className: "app-button-class",
        }}
        footer={footerCheck?undefined:null}
      >
        <div className="refinement-detail-modal">
          <div>
            <div className={styles["treatment-row"]}>
              <label>Scan Files</label>
              <span className="detail">
                Updated:
                {refinementData?.updatedAt ? ` ${getFormatDate(refinementData?.updatedAt)}` : "--"}
              </span>
            </div>

            {fileList.scanFiles?.length ? <AppFileList files={fileList.scanFiles} /> : null}
          </div>
          <Divider styles={{ margin: "5px 0" }} />
          <div>
            <label>Xray / Clinical Files</label>

            {fileList.clinicalFiles?.length ? <AppFileList files={fileList.clinicalFiles} /> : null}
          </div>
          <Divider />
          <label>Additional Detail</label>
          <div className="detail">
            <div
              dangerouslySetInnerHTML={{
                __html: refinementData?.details ? refinementData?.details : "--",
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RequestRefinementModal;
