import { Radio, Row, Col } from "antd";
import styles from "./FutureAnalytics/Graphs/phasing.module.scss";
const options = [
  {
    label: "Today",
    value: "1",
  },
  {
    label: "Future",
    value: "2",
  },
];
const TabSwitcher = ({ tabType, setTabTypes }) => {
  const tabChangeHandler = e => {
    setTabTypes(e.target.value);
  };

  return (
    <div style={{ marginBottom: "15px" }}>
      <Row align="middle" gutter={[16, 16]}>
        <Col>
          <h2 style={{ fontSize: "24px", fontWeight: "600", margin: "0px" }}>
            Dashboard
          </h2>
        </Col>
        <Col>
          <div className={styles.tabSwitcher}>
            <Radio.Group
              className={styles.patientDetailTopRowSwitcher}
              options={options}
              onChange={tabChangeHandler}
              value={tabType}
              optionType="button"
              buttonStyle="solid"
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default TabSwitcher;
