import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import BarChart from "components/shared/BarChart/BarChart";
import Box from "../../Box/Box";
import { getWeeklyPhasingOrders } from "api/treatment";
import { ReactComponent as Arrow } from "assets/images/Arrow.svg";

import css from "./phasing.module.scss";

dayjs.extend(isoWeek);

const generateWeekLabels = (date, weeksFromNow = 0) => {
  const startOfWeek = dayjs(date).isoWeekday(1).add(weeksFromNow, "week"); // Monday of the target week

  return Array.from({ length: 7 }, (_, i) => {
    const currentDate = startOfWeek.add(i, "day");
    return {
      label: currentDate.format("D MMM"), // Label in 'D MMM' format
      isPast: currentDate.isBefore(dayjs(), "day"), // Check if the date is in the past
      date: currentDate.format("YYYY-MM-DD"),
    };
  });
};

const PhasingOrders = ({ isFuture, labId, titleText, cardTitle, setPhasingMax, maxPhasing }) => {
  const history = useHistory();
  const [graphData, setGraphData] = useState([]);
  const [graphLabels, setGraphLabels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (labId) {
      (async () => {
        try {
          setIsLoading(true);
          const labels = generateWeekLabels(dayjs(), isFuture ? 1 : 0);
          const response = await getWeeklyPhasingOrders(
            labId,
            labels[0].date,
            labels[labels.length - 1].date
          );
          const { data } = response.data;
          // const labels = generateWeekLabels(dayjs(), isFuture ? 1 : 0);
          const dataArray = [];

          labels.forEach(label => {
            if (data[label.date]) {
              dataArray.push(data[label.date]);
            } else {
              dataArray.push(0);
            }
          });
           const maxValue = Math.max(...dataArray);
           setPhasingMax(maxValue)

          setGraphData(dataArray);
          setGraphLabels(labels);
        } catch (err) {
          console.log("err", err);
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [isFuture, labId]);

  // const onBarClick = (label, value) => {
  //   console.log("labelllll", label)
  //   const queryParams = new URLSearchParams({
  //     column: "phasingDate",
  //     startDate: label.date,
  //     endDate: label.date,
  //   }).toString();

  //   history.push(`/lab/patients?${queryParams}`);
  // };

  const generateQueryParams = (column, startDate, endDate) => {
    return new URLSearchParams({
      column,
      startDate,
      endDate,
    }).toString();
  };

  const onBarClick = (label) => {
    const queryParams = generateQueryParams("phasingDate", label.date, label.date);
    history.push(`/lab/patients?${queryParams}`);
  };

  const onArrowClick = () => {
    if (graphLabels.length > 0) {
      const queryParams = generateQueryParams(
        "phasingDate",
        graphLabels[0].date,
        graphLabels[graphLabels.length - 1].date
      );
      history.push(`/lab/patients?${queryParams}`);
    }
  };

  const total = graphData.reduce((acc, prev) => {
    return acc + prev;
  }, 0);
  return (
    <Box
      className={css["graph-title"]}
      cardTitle={
        <div style={{ textAlign: "left" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ fontSize: "18px", fontWeight: "700" }}>
              {cardTitle}
            </div>
            <div onClick={() => onArrowClick() }>
            <Arrow />
            </div>
   
          </div>

          <div style={{ fontSize: "34px", fontWeight: "800" }}>{total}</div>
        </div>
      }
      isLoading={isLoading}
      hoverable
    >
      <BarChart
        legend="Phasing Orders"
        labels={graphLabels}
        onBarClick={onBarClick}
        data={graphData}
        titleText={titleText}
        maxPhasing={maxPhasing}
      />
    </Box>
  );
};

export default PhasingOrders;
