
import dayjs from "dayjs";
import { Row, Col, Divider } from "antd";
import {ReactComponent as UserPdf} from "assets/images/UserPdf.svg";
import { primaryColor } from "colors-config";
import { getDisplayGender } from "utils/Patient";
const PatientDetails = ({ patientInfo, clinic }) => {
  return (
    <div className="treatmentPDF-patientContainer">
        <Divider/>
        <Row align="top" justify="space-between" gutter={[48,48]}>
        <Col lg={2} span={2}>
         <UserPdf/>
        </Col>
        <Col lg={11} span={11}>
        <h3 style={{margin:"0px"}}>Patient Details</h3>
          <div className="treatmentPDF-patientRow">
            <label>Patient Name</label>
            <b>
              {patientInfo?.patient_first_name
                ? patientInfo?.patient_first_name
                : ""}{" "}
              {patientInfo?.patient_first_name
                ? patientInfo?.patient_last_name
                : "--"}
            </b>
          </div>
          <div className="treatmentPDF-patientRow">
            <label>Gender</label>
            <b>
              {getDisplayGender(patientInfo)}
            </b>
          </div>
          <div className="treatmentPDF-patientRow">
            <label>Date of Birth</label>
            <b>
              {patientInfo?.patient_date_of_birth
                ? dayjs(patientInfo?.patient_date_of_birth).format("DD-MM-YYYY")
                : "--"}
            </b>
          </div>
          <div className="treatmentPDF-patientRow">
            <label>Phone Number</label>
            <b>
            {patientInfo?.patient_country_code_name
                ? `${patientInfo?.patient_country_code_name}-`
                : "--"}
              {patientInfo?.patient_phone_number
                ? patientInfo?.patient_phone_number
                : "--"}
            </b>
          </div>
          <div className="treatmentPDF-patientRow">
            <label>Address</label>
            <b>
              {patientInfo?.patient_address
                ? `${patientInfo?.patient_address},`
                : "--"}
              {patientInfo?.patient_address &&       <br/>}
                {patientInfo?.patient_city
                ? `${patientInfo?.patient_city}`
                : ""}
                {patientInfo?.patient_county
                ? `,${patientInfo?.patient_county},`
                : ""}
                {patientInfo?.patient_county &&       <br/>}
                {patientInfo?.patient_country
                ? `${patientInfo?.patient_country}`
                : ""}
                {patientInfo?.patient_postal_code
                ? `,${patientInfo?.patient_postal_code}`
                : ""}
            </b>
          </div>
        </Col>
        <Col lg={11} span={11}>
        <h3 style={{margin:"0px"}}>Clinic Details</h3>
          <div className="treatmentPDF-patientRow">
            <label>Clinic Name</label>
            <b style={{color: primaryColor}}>
                {clinic?.clinic_name
                ? `${clinic?.clinic_name}`
                : "--"}
               
            </b>
          </div>
          {/* <div className="treatmentPDF-patientRow">
            <label>Doctor Name</label>
            <b>
              {patientInfo?.clinicianDetails?.clinician_name
                ? patientInfo?.clinicianDetails?.clinician_name
                : "--"}
            </b>
          </div> */}
          <div className="treatmentPDF-patientRow">
            <label>Email </label>
            <span>
              {" "}
              {clinic?.clinic_email
                ? clinic?.clinic_email
                : "--"}
            </span>
          </div>
          <div className="treatmentPDF-patientRow">
            <label>Phone Number</label>
            <span>
            {clinic?.country_code_name
                ? clinic?.country_code_name
                : ""}
              -
              {clinic?.clinic_contact
                ? clinic?.clinic_contact
                : "--"}
            </span>
          </div>
          <div className="treatmentPDF-patientRow">
            <label>Address</label>
            <span> 
                {clinic?.clinic_address
                ? `${clinic?.clinic_address},`
                : "--"}
         
                {clinic?.clinic_address &&    <br/>}
                {clinic?.clinic_city
                ? `${clinic?.clinic_city}`
                : ""}
                {clinic?.clinic_county
                ? `,${clinic?.clinic_county},`
                : ""}
                {clinic?.clinic_county &&       <br/>}
                {clinic?.clinic_country
                ? `${clinic?.clinic_country}`
                : ""}
                {clinic?.clinic_zipcode
                ? `,${clinic?.clinic_zipcode}`
                : ""}
                
                
                </span>
          </div>
        </Col>
      </Row>
      <Divider />
    </div>
  );
};

export default PatientDetails;
