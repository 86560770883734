import React, { useEffect, useRef, useState } from "react";
import { UPPER_JAW, LOWER_JAW } from "components/private/IPRInfo/data";
import Aligners from "components/private/IPRInfo/IPRAligners";
import Tooth from "components/private/IPRInfo/Tooth/Tooth";

const IPR = ({ upperAligners, chartValue, lowerAligners, hideTeethParam }) => {
  const [upperStructure, setUpperStructure] = useState(UPPER_JAW);
  const [lowerStructure, setLowerStructure] = useState(LOWER_JAW);
  const targetDivRef = useRef(null);

  // useEffect(() => {
  //   // Scroll to the target div when the component mounts
  //   if (targetDivRef.current) {
  //     targetDivRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, []);


  return (
    <div>
      <div className="ipr-detail-container">
        <div className="teethChart-container" style={{ padding: "20px 0px" }}>
          {hideTeethParam !== "upper" && (
            <Aligners alignersObj={upperAligners} alignerType="upper" />
          )}

          <div className="teethRow-container" style={{ marginTop: "0px" }}>
            {hideTeethParam !== "upper" && <div className="teethRow-bgOne" />}
            {/* <div ref={targetDivRef}></div> */}
            <div className="teethRow-one ipr-row" >
              {hideTeethParam !== "upper" && (
                <>
                  {upperStructure.map(tooth => (
                    <div key={tooth.id}>
                      <Tooth
                        type="upper"
                        tooth={tooth}
                        chartValue={chartValue}
                      />
                    </div>
                  ))}
                </>
              )}
              {!hideTeethParam.length && (
                <div className="tooth-sides">
                  <h2>Right</h2>
                  <h2>Left</h2>
                </div>
              )}

              {hideTeethParam !== "lower" && (
                <>
                  {lowerStructure.map(tooth => (
                    <div key={tooth.id}>
                      <Tooth
                        type="lower"
                        tooth={tooth}
                        chartValue={chartValue}
                      />
                    </div>
                  ))}
                </>
              )}
            </div>
            {hideTeethParam !== "lower" && (
              <div className="teethRow-bgTwo"></div>
            )}
          </div>
          {hideTeethParam !== "lower" && (
            <Aligners alignersObj={lowerAligners} alignerType="lower" />
          )}
        </div>
      </div>
    </div>
  );
};

export default IPR;
