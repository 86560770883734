import { message } from "antd";
import { postTreatmentWithMedia } from "../api";
import { deleteTreatmentDraftService } from "api/treatment";
import { updateClinicIntercomJourneyStatus } from "api/clinic";

export const confirmTreatment = async (
  val,
  patientId,
  labId,
  getTreatmentSetupAndPrescription,
  iprFile
) => {
  const model = {
    patient_id: patientId,
    lab_id: labId,
    num_of_upper_aligners: val.upperAlignersNo,
    num_of_lower_aligners: val.lowerAlignersNo,
    attachments: val.attachments,
    iprData: val.iprData,
    treatment_plan_link: val.planUrl,
    revision: val.revision,
    details: val.details,
    labProductId: val.labProductId ? val.labProductId : null,
    quantity: val.quantity ? val.quantity : null,
    attachmentData: val.attachmentData,
    attachmentNames: val.attachmentNames,
    fileId: val.fileId,
    treatmentLabProductList: val.treatmentLabProductList
  };
  
  try {
    let formData = new FormData();
    formData.append("phasing", val.phasing? val.phasing:"");
    formData.append("phasingOption", val.phasingOption);
    formData.append("labProductId", model.labProductId ? model.labProductId : "");
    formData.append("quantity", model.quantity);
    formData.append("patient_id", model.patient_id);
    formData.append("lab_id", model.lab_id);
    formData.append("num_of_upper_aligners", model.num_of_upper_aligners);
    formData.append("num_of_lower_aligners", model.num_of_lower_aligners);
    formData.append("attachments", model.attachments ? model.attachments : "");
    formData.append("iprData", JSON.stringify(model.iprData));
    formData.append("attachmentData", JSON.stringify(model.attachmentData));
    formData.append("treatment_plan_link", model.treatment_plan_link?model.treatment_plan_link:"");
    formData.append("revision", model.revision ? model.revision : "");
    formData.append("additionalDetails", model.details ? model.details : "");
    formData.append("file", iprFile);
    formData.append("update", val.newVersion ? "true" : "");
    formData.append("removeIprFile", val?.removeIprFile && !iprFile ? true : "");
    formData.append("manualTreatmentFileId", val?.manualTreatmentFileId && !iprFile ? val?.manualTreatmentFileId : "");
    formData.append(
      "old_lab_treatment_id",
      val?.old_lab_treatment_id ? val.old_lab_treatment_id : ""
    );
    formData.append("attachmentNames", JSON.stringify(model.attachmentNames));
    formData.append("fileId", model.fileId ? model.fileId: "");
    formData.append("treatmentLabProductList", JSON.stringify(model.treatmentLabProductList));
    formData.append("phasingList", JSON.stringify(val.phasingList));
    const config = {
      headers: { "content-type": "multipart/form-data" },
      onUploadProgress: (event) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        // setProgress(percent);
        if (percent === 100) {
        }
      },
    };

    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + " - " + pair[1]);
    // }
  const {data} =   await postTreatmentWithMedia(formData, config);
    deleteTreatmentDraftService({
      patientId: model.patient_id,
    });
    // getTreatmentSetupAndPrescription();
    if(val?.clinicInfo?.firstTimeOnboard){
      updateClinicIntercomJourneyStatus(val?.clinicInfo?.clinic_id, {
        firstTimeOnboardStep: "APPROVE_PLAN"
      })
    }
   
    return data
  } catch (error) {
    console.log(error);
    if (error.response.data?.err?.msg) {
      message.error(error.response.data.err.msg);
    } else {
      message.error("Something went wrong. Please try again.");
    }
  }
};
