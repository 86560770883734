import React, {useState, useEffect} from "react";
import { Form, Input, Select, Row, Radio, Col, Divider } from "antd";
// import { ReactComponent as SelectIcon } from "../../../../assets/images/selectIcon.svg";
import { FORM_CONSTANTS } from "../../constants";

const { Option } = Select;
const { TextArea } = Input;

const Occlusion = ({
  onFormChange,
  disabled,
  skeletal,
  dental,
  setDental,
  setSkeletal,
  prescriptionForm
}) => {
  const [disableSkeletal, setDisableSkeletal] = useState(true);
  const [disableDental, setDisableDental] = useState(true);



  useEffect(() => {
    const dentalClassValue = prescriptionForm?.getFieldValue("dentalClass");
    const skeletalClassValue = prescriptionForm?.getFieldValue("skeletalClass");  
    setDisableSkeletal(!skeletalClassValue);
    setDisableDental(!dentalClassValue);
    setDental(dentalClassValue === FORM_CONSTANTS.CLASS_TWO);
    setSkeletal(skeletalClassValue === FORM_CONSTANTS.CLASS_TWO);
  }, [prescriptionForm, prescriptionForm?.getFieldValue("dentalClass"), prescriptionForm?.getFieldValue("skeletalClass")]);



  const handleClassChange = (name, value, setter) => {
    setter(value === FORM_CONSTANTS.CLASS_TWO);
    onFormChange(name, value);
    if (name === "dentalClass") {
      setDisableDental(false) 
    }
    if (name === "skeletalClass") {
      setDisableSkeletal(false)
    }
  };

  const onSideChange = (value, name, setter) => {
    onFormChange(name, value);
  };


  return (
    <div
      style={{
        background: "#fff",
        margin: "20px 0",
        padding: "0px 0px 42px  20px",
        borderRadius: "8px",
      }}
    >
      <h1
        className="form-heading"
        style={{ padding: "24px 20px 0px 0px", margin: "0px" }}
      >
        Occlusion
      </h1>
      <Divider />
      <div
        className="skeletal-row"
        style={{ textAlign: "left", marginBottom: "20px", marginTop: "20px" }}
      >
        <label>Angle’s Class</label>
      </div>
      <div className="skeletal-row">
        <Row gutter={[8,8]} align="middle" style={{ marginBottom: "24px" }}>
          <Col lg={3}>
            <label>Skeletal</label>
          </Col>
          <Col lg={5}>
            <Form.Item name="skeletalClass" label="">
              <Select
                placeholder="Select Class"
                size="large"
                onChange={(value) =>
                  handleClassChange("skeletalClass", value, setSkeletal)
                }
                disabled={disabled }
              >
                <Option value={FORM_CONSTANTS.CLASS_ONE}>Class 1</Option>
                <Option value={FORM_CONSTANTS.CLASS_TWO}>Class 2</Option>
                <Option value={FORM_CONSTANTS.CLASS_THREE}>Class 3</Option>
                
              </Select>
            </Form.Item>
          </Col>
          {skeletal && (
            <Col lg={5}>
              <Form.Item
                name="skeletalDivision"
                label=""
                onChange={(value) => onFormChange("skeletalDivision", value)}
              >
                <Select
                  size="large"
                  placeholder="Select Division"
                  disabled={disabled}
                >
                  <Option value={FORM_CONSTANTS.DIVISION_ONE}>
                    Division 1
                  </Option>
                  <Option value={FORM_CONSTANTS.DIVISION_TWO}>
                    Division 2
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          )}

          <Col lg={11}>
            <Form.Item name="skeletalSide" label="">
              <Radio.Group
                onChange={(e) =>
                  onSideChange(
                    e.target.value,
                    "skeletalSide",
                  )
                }
                disabled={disabled || disableSkeletal}
              >
                    <Radio value={FORM_CONSTANTS.LEFT}>Left</Radio>
                    <Radio value={FORM_CONSTANTS.RIGHT}>Right</Radio>
                <Radio value={FORM_CONSTANTS.BOTH}>Both</Radio>
                <Radio value={FORM_CONSTANTS.N_A}>N/A</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </div>

      <div className="skeletal-row">
        <Row gutter={[8,8]} align="middle" style={{ marginBottom: "24px" }}>
          <Col lg={3}>
            <label>Dental</label>
          </Col>
          <Col lg={5}>
            <Form.Item name="dentalClass" label="">
              <Select
                placeholder="Select Dental"
                size="large"
                onChange={(value) =>
                  handleClassChange("dentalClass", value, setDental)
                }
                disabled={disabled}
              >
                <Option value={FORM_CONSTANTS.CLASS_ONE}>Class 1</Option>
                <Option value={FORM_CONSTANTS.CLASS_TWO}>Class 2</Option>
                <Option value={FORM_CONSTANTS.CLASS_THREE}>Class 3</Option>
              </Select>
            </Form.Item>
          </Col>

          {dental && (
            <Col lg={5}>
              <Form.Item name="dentalDivision" label="">
                <Select
                  placeholder="Select Division"
                  size="large"
                  onChange={(value) => onFormChange("dentalDivision", value)}
                  disabled={disabled}
                >
                  <Option value={FORM_CONSTANTS.DIVISION_ONE}>
                    Division 1
                  </Option>
                  <Option value={FORM_CONSTANTS.DIVISION_TWO}>
                    Division 2
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          )}

          <Col lg={11}>
            <Form.Item name="dentalSide" label="">
              <Radio.Group
                disabled={disabled || disableDental}
                onChange={(e) =>
                  onSideChange(
                    e.target.value,
                    "dentalSide",
                    setDisableDental
                  )
                }
              >
                <Radio value={FORM_CONSTANTS.LEFT}>Left</Radio>
                <Radio value={FORM_CONSTANTS.RIGHT}>Right</Radio>
                <Radio value={FORM_CONSTANTS.BOTH}>Both</Radio>
                <Radio value={FORM_CONSTANTS.N_A}>N/A</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </div>
      <div className="skeletal-row">
        <Row>
          <Col lg={24}>
            <Form.Item
              name="parafunctionDetails"
              label="Parafunction/Deviation"
            >
              <TextArea
                rows={5}
                onChange={(e) =>
                  onFormChange("parafunctionDetails", e.target.value)
                }
                disabled={disabled}
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default React.memo(Occlusion);
