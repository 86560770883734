import { companyType, primaryColor } from "colors-config";
// import CustomIcon from "../CustomIcon/CustomIcon";
// import { ReactComponent as ScanFile } from "assets/images/ScanFile.svg"; // Import your SVG file

const CustomEditIcon = ({ style, ...props }) => {
  let color='#0A8196'
  if(companyType===5){
   color='#a1582e';
  }
  const svgFile = (<svg width="40" height="40" viewBox="0 0 23 30" style={{marginTop:"-10px"}} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.72866 1.58203C1.35653 1.58203 0.244263 2.7367 0.244263 4.14797V14.4117V19.5436V27.2414C0.244263 28.6527 1.35653 29.8073 2.72866 29.8073H20.1194C21.492 29.8073 22.6038 28.6527 22.6038 27.2414V19.5436V14.4117V9.27984L15.1506 1.58203H2.72866Z" fill={color} />
    <path d="M2.72866 0.300781C1.35653 0.300781 0.244263 1.45545 0.244263 2.86672V13.1305V18.2623V25.9602C0.244263 27.3714 1.35653 28.5261 2.72866 28.5261H20.1194C21.492 28.5261 22.6038 27.3714 22.6038 25.9602V18.2623V13.1305V7.99859L15.1506 0.300781H2.72866Z" fill={primaryColor} />
    <path d="M22.6003 7.99859L15.1471 0.300781V5.43266C15.1471 6.84392 16.2589 7.99859 17.6315 7.99859H22.6003Z" fill={color} />
  </svg>)
  // return <CustomIcon style={style} {...props} iconComponent={svgFile} />;
  return svgFile
};

export default CustomEditIcon