import React from "react";
import { Row, Col, Form, InputNumber, message } from "antd";
import PropTypes from "prop-types";
import { primaryColor } from "colors-config";

const DiscountForm = ({ name, fieldKey, restField, productCurrency, form }) => {
  const setDiscountFields = (
    name,
    discountAmount,
    discountPrice,
    discountPercentage
  ) => {
    form.setFields([
      {
        name: [`products`, name, "discountAmount"],
        value: discountAmount,
      },
      {
        name: [`products`, name, "discountPrice"],
        value: discountPrice < 0 ? 0 : discountPrice,
      },
      {
        name: [`products`, name, "discountPercentage"],
        value: discountPercentage,
      },
    ]);
  };

  const calculateFormDiscountPrice = (discountAmount, productPrice) => {
    return productPrice ? (productPrice - discountAmount).toFixed(2) : "0.00";
  };

  const onDiscountPercentageChange = (value) => {
    const productPrice = form.getFieldValue(["products", name, "newPrice"]);
    const discountAmountViaPercentage = (value / 100) * productPrice;
    const totalDiscountPrice = (
      productPrice - discountAmountViaPercentage || 0
    ).toFixed(2);
    setDiscountFields(name, discountAmountViaPercentage, totalDiscountPrice, value);
  };

  // Handle discount amount change
  const onDiscountAmountChange = (value) => {
    // If the discount is less than 0 or empty, set fields and return an error
    if (value < 0 || !value) {
      setDiscountFields(name, value, null, null);
      return message.error("Discount cannot be a negative number or empty.");
    }
  
    const productPrice = form.getFieldValue(["products", name, "newPrice"]);
  
    // If there's no product price or the discount exceeds or equals the product price, set discount price and percentage to null
    if (!productPrice || value >= productPrice) {
      setDiscountFields(name, value, null, null);
    } else {
      // Calculate the discount price
      const totalDiscountPrice = calculateFormDiscountPrice(value, productPrice);
  
      // Calculate the discount percentage
      const discountPercentage = ((value / productPrice) * 100).toFixed(2);
  
      // Set the fields with the calculated discount price and percentage
      setDiscountFields(name, value, totalDiscountPrice, discountPercentage);
    }
  };
  

  return (
    <Row gutter={[16, 16]} align="middle">
      <Col lg={9}>
        <span>Discount</span>
      </Col>

      <Col lg={5}>
        <Form.Item
          {...restField}
          name={[name, "discountPercentage"]}
          fieldKey={[fieldKey, "discountPercentage"]}
          label=""
          placeholder="Discount Percentage"
          style={{ margin: "0px" }}
        >
          <InputNumber
            placeholder="%"
            min={0}
            max={100}
            step={0.1}
            suffix={<b style={{ color: primaryColor }}>%</b>}
            style={{ width: "100%" }}
            onChange={onDiscountPercentageChange}
          />
        </Form.Item>
      </Col>

      <Col lg={10}>
        <Form.Item
          {...restField}
          name={[name, "discountAmount"]}
          fieldKey={[fieldKey, "discountAmount"]}
          label=""
          placeholder="Discount Amount"
          style={{ marginBottom: "0px" }}
        >
          <InputNumber
            placeholder="Amount"
            step={0.01}
            precision={2}
            style={{ width: "100%" }}
            suffix={
              <span style={{ color: primaryColor }}>{productCurrency}</span>
            }
            onChange={onDiscountAmountChange}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

// Define prop types
DiscountForm.propTypes = {
  name: PropTypes.string.isRequired,
  fieldKey: PropTypes.string.isRequired,
  restField: PropTypes.object.isRequired,
  productCurrency: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired, // Add form as a required prop
};

export default DiscountForm;
