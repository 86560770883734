export const USER_TABS = {
  USER: "USER",
  ALL: "ALL",
};

export const patientTableColsList = [
  "clinicianDetails",
  "serviceType",
  "dateCreated",
  "phasingDate",
  "dateOrdered",
  "assignee",
  "orderStatus",
  "sla",
];

export const colOptions = [
  { value: "clinicianDetails", label: "Clinic Details", order: 1 },
  { value: "serviceType", label: "Service Type", order: 2 },
  { value: "dateCreated", label: "Date Created", order: 3 },
  { value: "phasingDate", label: "Phasing Due Date", order: 4 },
  { value: "dateOrdered", label: "Date Ordered", order: 5 },
  { value: "assignee", label: "Assignee", order: 6 },
  { value: "orderStatus", label: "Order Status", order: 7 },
  { value: "sla", label: "Task Time", order: 8 },
];
