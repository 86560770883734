import { useRef } from "react";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { primaryColor } from "colors-config";

// Register required Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = ({ labels, data, legend, titleText, onBarClick, maxPhasing }) => {
  const chartRef = useRef(null);
  // console.log("labels", labels);
  const handleClick = event => {
    const chart = chartRef.current;
    if (!chart) return;

    // Get the clicked bar
    const element = chart.getElementsAtEventForMode(
      event.nativeEvent,
      "nearest", // Detect the nearest bar
      { intersect: true }, // Only if click intersects with a bar
      true
    );

    if (element.length > 0) {
      const { index } = element[0];
      const label = labels[index];
      const value = data[index];

      // Perform your desired action
      // console.log(`You clicked on ${label}: ${value}`);

      onBarClick(label, value);
    }
  };



  const backgroundColors = labels.map(label =>
    label.isPast ? "#DBDBDB" : primaryColor
  );

  const barChartData = {
    labels: labels.map(el => el.label),
    datasets: [
      {
        label: legend,
        data,
        backgroundColor: backgroundColors,
        borderColor: backgroundColors,
        borderWidth: 3,
        barThickness: 6,
        borderRadius: 8,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Allows height to be customized independently
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: false,
        text: titleText,
      },
    },
    scales: {
      x: {
        ticks: {
          color: ctx => {
            const labelIndex = ctx.index;
            return labels[labelIndex].isPast ? "grey" : "black"; // Grey for past dates, black for others
          },
          maxRotation: 0,
          minRotation: 0,
        },
      },
      y: {
        beginAtZero: true,
       suggestedMax: maxPhasing || 10, // Start with a range of 0-10
        ticks: {
          stepSize: 1,
          callback: value => {
            return Number(value);
          },
        },
      },
    },
  };

  return (
    <div style={{ width: "100%", height: "250px" /* Customize height here */ }}>
    <Bar
      ref={chartRef}
      data={barChartData}
      options={options}
      onClick={handleClick}
    />
  </div>
  );
};

BarChart.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.number).isRequired,
  length: PropTypes.string.isRequired,
  titleText: PropTypes.string.isRequired,
};

export default BarChart;
