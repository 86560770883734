import React from "react";
import { Modal, Button } from "antd";
import { handlePrintingStatusMessage } from "utils/order";
import DispatchDateForm from "../DispatchDateForm/DispatchDateForm";

const PrintOrderModal = ({
  showPrintingConfirmationModal,
  setShowPrintingConfirmationModal,
  onConfirmation,
  loading,
  printForm,
  orderDetails,
}) => {
  const handleOk = () => {
    if (orderDetails?.order_delivery_status_id === 6) {
      return printForm.submit();
    }
    onConfirmation(
      null,
      orderDetails?.order_delivery_status_id === 7 ? true : false
    );
  };

  const handleCancel = () => {
    setShowPrintingConfirmationModal(false);
  };

  const message = handlePrintingStatusMessage(
    orderDetails?.order_delivery_status_id,
    orderDetails?.patient_order_id
  );

  return (
    <Modal
      title={
        orderDetails?.order_delivery_status_id === 7 &&
        orderDetails?.patient_order_id
          ? "Pause Printing"
          : "Start Printing"
      }
      okText="Confirm"
      cancelText="Cancel"
      open={showPrintingConfirmationModal}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button
          key="cancel"
          onClick={handleCancel}
          shape="round"
          style={{ width: "7rem" }}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          shape="round"
          onClick={handleOk}
          loading={loading}
          style={{ width: "7rem" }}
        >
          Confirm
        </Button>,
      ]}
    >
      <p>{message}</p>
      {orderDetails?.order_delivery_status_id === 6 && (
        <DispatchDateForm
          onConfirmation={onConfirmation}
          printForm={printForm}
        />
      )}
    </Modal>
  );
};

export default PrintOrderModal;
