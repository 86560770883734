import "./table.scss"
const TreatmentProductToolTip = ({currency, price, netPrice, discountPrice}) => {
  return (
    <div className="product-toolTip">
      <p>{`Price : ${currency} ${netPrice}`}</p>
      <p>{`Discount : ${currency} ${discountPrice}`}</p>
      <div className="border-line" />
      <b>{`Net Price : ${currency} ${netPrice - discountPrice}`}</b>
    </div>
  );
};

export default TreatmentProductToolTip;
