import { useState, useEffect } from "react";
import TreatmentSetupComponent from "./Components/TreatmentSetupComponent";
import { getTreatmentChecks } from "./utils";
import NoTreatmentSetupView from "./Components/NoTreatmentSetupView";
import {
  addIprGuideAPI,
  addResourceAPI,
  cancelTreatmentAPI,
  deleteIPRGuideAPI,
  getRefinementData,
  uploadIprGuideAPI,
} from "./api";
import { message } from "antd";
import "../../../../assets/styles/components/TreatmentPlanDetails.scss";
import { confirmTreatment } from "./Services/services";
import { prescriptionDataMapper } from "components/private/patient-detail/newPrescriptionForm/utils";
import { getPatientPrescriptionDetails } from "components/private/patient-detail/PatientTreatment/api";
import TreatmentSetupModal from "../TreatmentModal/TreatmentModal";

const PatientTreatmentDetails = ({
  wholeTreatmentPlan,
  labOrders,
  treatmentSetup,
  setTreatmentSetup,
  isTreatmentCreationAllowed,
  patientInfo,
  patientOrders,
  setShowLabOrderModal,
  patientId,
  labId,
  getTreatmentSetupAndPrescription,
  treatmentPlans,
  getPatientInformation,
  iprData,
  setIprData,
  attachmentData,
  setAttachmentData,
  patientOrderStatus,
  alignerOrders,
  scanUrls,
  setScanUrls,
  onPlaceOrderClickFromTP,
  clinicId,
  activeTreatment,
  refinements /*array of not applied refinements*/,
  aligners /* aligners list and current aligner id */,
  getData,
  setOrderType,
  clinicInfo,
  setIsPhaseOrder
}) => {
  const [treatment, setTreatment] = useState({});
  const [checkTreatment, setCheckTreatment] = useState([]);
  const [labOrder, setLabOrder] = useState({});
  let [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [showTreatmentSetupModal, setShowTreatmentSetupModal] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showOlderPlansModal, setShowOlderPlansModal] = useState(false);
  const [deleteModalLoading, setDeleteModalLoading] = useState(false);
  const [iprList, setIprList] = useState([]);
  const [selectedTreatment, setSelectedTreatment] = useState();
  const [refinementData, setRefinementData] = useState(null);
  const [prescriptionObj, setPrescriptionObj] = useState({});

  
  useEffect(() => {
    if (patientId) {
      getRefinementDetails(patientId);
    }
  }, [patientId]);

  useEffect(() => {
    (async () => {
      try {
        // setLoading(true);
        const { data } = await getPatientPrescriptionDetails(patientId);
        // console.log("datattata", data);
        const finalObj = prescriptionDataMapper(data?.prescription);
        // console.log("finalObjfinalObjfinalObj", finalObj);
        setPrescriptionObj(finalObj);
      } catch (error) {
        console.log(error, "errror");
      } finally {
        // setLoading(false);
      }
    })();
  }, [patientId]);

  useEffect(() => {
    if (treatmentSetup.length) {
      setTreatment(treatmentSetup[0]);
    }
    if (treatmentSetup[0]?.ipr_guide_link && treatmentSetup[0]?.ipr_guide_link !== "") {
      setIprList([
        {
          uid: 1,
          name: "IPR Guide",
          status: "done",
          url: treatmentSetup[0]?.ipr_guide_link ? treatmentSetup[0]?.ipr_guide_link : "",
        },
      ]);
    }
  }, [treatmentSetup]);

  useEffect(() => {
    if (labOrders?.length) {
      setLabOrder(labOrders[0]);
    }
  }, [labOrders]);

  useEffect(() => {
    const treatmentCheck = getTreatmentChecks(wholeTreatmentPlan);
    setCheckTreatment(treatmentCheck);
    const findApprovedTreatment = treatmentCheck?.find((obj) => obj?.approved);
    setSelectedTreatment(
      findApprovedTreatment
        ? findApprovedTreatment
        : treatmentCheck?.length
        ? treatmentCheck[0]
        : null
    );
    setTreatment(
      findApprovedTreatment
        ? findApprovedTreatment
        : treatmentCheck?.length
        ? treatmentCheck[0]
        : {}
    );
  }, [wholeTreatmentPlan, treatmentSetup]);

  const getRefinementDetails = async (patientId) => {
    const refinementDetailsRes = await getRefinementData(patientId);
    if (refinementDetailsRes?.data?.data) {
      setRefinementData(refinementDetailsRes.data.data);
    }
  };

  const handleChange = (treatment) => {
    setTreatment(JSON.parse(JSON.stringify(treatment)));
    setIprData(treatment.iprData);
    setAttachmentData(treatment.attachmentData);
  };

  const handleIprListChange = async (info) => {
    let newFileList = [...info.fileList];
    newFileList = newFileList.slice(-2);
    newFileList = newFileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    if (newFileList?.length === 0) {
      await deleteIPRGuideAPI(treatment);
      const updatedTreatment = { ...treatment, ipr_guide_link: "" };
      setTreatmentSetup([updatedTreatment]);
      message.success("Deleted IPR successfully!");
    }
    setIprList(newFileList);
  };

  const handleModalOk = async () => {
    setDeleteModalLoading(true);
    try {
      await cancelTreatmentAPI(patientId);
      getTreatmentSetupAndPrescription();
      getPatientInformation();
      message.success("Treatment cancelled successfully");
    } catch (error) {
      message.error("Some Error occurred!");
    } finally {
      setDeleteModalOpen(false);
      setDeleteModalLoading(false);
    }
  };

  const handleModalCancel = () => {
    setDeleteModalOpen(false);
  };
  async function uploadFiles(options) {
    const { onSuccess, onError, file, onProgress, filename } = options;
    const fileNameArray = file.name.split(".");
    fileNameArray[0] = fileNameArray[0] + "-" + new Date().valueOf();
    const fileName = fileNameArray.join(".");
    const fmData = new FormData();
    const config = {
      headers: {
        "content-type": file.type,
      },

      onUploadProgress: (event) => {
        const percent = Math.floor((event.loaded / event.total) * 100);

        if (percent === 100) {
        }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    fmData.append("file", file);
    try {
      const preSignRequestBody = {
        lab_id: labId,
        file_name: fileName,
        resource_type: "file",
        file_type: file.type.split("/")[0] === "application" ? "pdf" : "image",
        lab_document_type: "IPR",
      };

      const preSignedResponse = await addResourceAPI(preSignRequestBody);
      const url = preSignedResponse.body.success.url?.split("?")[0];
      const uploadUrl = preSignedResponse.body.success.uploadUrl;
      await uploadIprGuideAPI(uploadUrl, file, config);
      await addIprGuideAPI(treatment, url);
      message.success("Added IPR successfully!");
      onSuccess("Ok");
      const updatedTreatment = { ...treatment, ipr_guide_link: url };
      setTreatmentSetup([updatedTreatment]);
    } catch (error) {
      console.log(error);
      onError({ error });
    }
  }
  const IprProps = {
    accept: ".pdf",
    multiple: false,
    customRequest: uploadFiles,
    onChange: handleIprListChange,
  };
  return (
    <>
      {showTreatmentSetupModal && (
        <TreatmentSetupModal
          refinements={refinements}
          showModal={showTreatmentSetupModal}
          onConfirmation={confirmTreatment}
          setShow={setShowTreatmentSetupModal}
          treatment={treatment}
          patientId={patientId}
          labId={labId}
          getTreatmentSetupAndPrescription={getTreatmentSetupAndPrescription}
          refinementData={refinementData}
          selectedTreatment={selectedTreatment}
          iprData={iprData}
          prescriptionObj={prescriptionObj}
          attachmentData={attachmentData}
          wholeTreatmentPlan={wholeTreatmentPlan}
          clinicId={clinicId}
          patientInfo={patientInfo}
          clinicInfo={clinicInfo}
        />
      )}
      {treatmentSetup.length > 0 && checkTreatment.length > 0 ? (
        <div className="patient-treatment-details-container">
          <TreatmentSetupComponent
          setIsPhaseOrder={setIsPhaseOrder}
            aligners={aligners}
            refinements={refinements}
            activeTreatment={activeTreatment}
            onPlaceOrderClickFromTP={onPlaceOrderClickFromTP}
            wholeTreatmentPlan={wholeTreatmentPlan}
            treatment={treatment}
            checkTreatment={checkTreatment}
            handleChange={handleChange}
            isTreatmentCreationAllowed={isTreatmentCreationAllowed}
            patientInfo={patientInfo}
            setDeleteModalOpen={setDeleteModalOpen}
            setShowTreatmentSetupModal={setShowTreatmentSetupModal}
            deleteModalOpen={deleteModalOpen}
            showTreatmentSetupModal={showTreatmentSetupModal}
            labOrders={labOrders}
            labOrder={labOrder}
            setIsModalVisible={setIsModalVisible}
            patientOrders={patientOrders}
            setShowLabOrderModal={setShowLabOrderModal}
            setShowOlderPlansModal={setShowOlderPlansModal}
            showOlderPlansModal={showOlderPlansModal}
            patientId={patientId}
            labId={labId}
            getTreatmentSetupAndPrescription={getTreatmentSetupAndPrescription}
            treatmentPlans={treatmentPlans}
            handleModalCancel={handleModalCancel}
            deleteModalLoading={deleteModalLoading}
            handleModalOk={handleModalOk}
            treatmentSetup={treatmentSetup}
            setTreatmentSetup={setTreatmentSetup}
            IprProps={IprProps}
            iprList={iprList}
            selectedTreatment={selectedTreatment}
            setSelectedTreatment={setSelectedTreatment}
            refinementData={refinementData}
            prescriptionObj={prescriptionObj}
            patientOrderStatus={patientOrderStatus}
            alignerOrders={alignerOrders}
            getRefinementDetails={getRefinementDetails}
            getData={getData}
            setOrderType={setOrderType}
          />
        </div>
      ) : (
        <div className="no-treatment-details-container">
          <NoTreatmentSetupView
            patientInfo={patientInfo}
            treatment={treatment}
            setShowTreatmentSetupModal={setShowTreatmentSetupModal}
            setDeleteModalOpen={setDeleteModalOpen}
            isTreatmentCreationAllowed={isTreatmentCreationAllowed}
            treatmentSetup={treatmentSetup}
            IprProps={IprProps}
            iprList={iprList}
            scanUrls={scanUrls}
            setScanUrls={setScanUrls}
          />
        </div>
      )}
    </>
  );
};

export default PatientTreatmentDetails;
