import React, { useMemo, useState } from "react";
import UserIcon from "../../assets/images/user_icon.svg";
import { ReactComponent as CalendarIcon } from "assets/images/calendar_icon.svg";
import { ReactComponent as SmartphoneIcon } from "assets/images/smartphone_icon.svg";
import { ReactComponent as AddressIcon } from "assets/images/address_icon.svg";
import { ReactComponent as EmailIcon } from "assets/images/envelope.svg";
import { connect } from "react-redux";
import moment from "moment";
import { Radio, Typography, Form, Tooltip } from "antd";
import { primaryColor, companyType } from "colors-config";
import AppTag from "components/shared/AppTag/AppTag";
import "../../assets/styles/components/patientsDetail.scss";
import { ReactComponent as EditIcon } from "assets/images/edit_icon.svg";
import AddOrEditPatient from "../shared/modals/CommentModal";
import EditPatientModal from "components/shared/modals/EditPatientModal";
import { UserOutlined } from "@ant-design/icons";
import { getDisplayGender } from "utils/Patient";
import dayjs from "dayjs";
const { Title } = Typography;

const PATIENT = "patient";
const CLINIC = "clinic";

const PatientsDetail = ({
  patientInfo,
  currentAlignerNumber,
  lab,
  totalAlignerState,
  clinicInfo,
  getPatientInformation
}) => {
  const [activeTab, setActiveTab] = useState(PATIENT);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editPatientForm] = Form.useForm();
  // const getClinicTime = () => {
  //   const dummyDate = "2017-03-13";
  //   if (clinicDetails?.clinic_start_time) {
  //     return moment(dummyDate + " " + clinicDetails?.clinic_start_time).format(
  //       "HH:mm"
  //     )
  //   }
  //   return "Not available";

  // }

  const onTypeChanged = (event) => {
    const selectedMenu = event.target.value;
    setActiveTab(selectedMenu);
  };

  const patientAddress = useMemo(() => {
    if (patientInfo) {
      const address = `${patientInfo?.patient_address || ""}${
        patientInfo?.patient_address && patientInfo?.patient_city ? "," : ""
      } ${patientInfo?.patient_city || ""}${
        patientInfo?.patient_county && patientInfo?.patient_city ? "," : ""
      } ${patientInfo?.patient_county || ""}${
        patientInfo?.patient_county && patientInfo?.patient_postal_code
          ? ","
          : ""
      } ${patientInfo?.patient_postal_code || ""}${
        patientInfo?.patient_country && patientInfo?.patient_postal_code
          ? ","
          : ""
      } ${patientInfo?.patient_country || ""}`;

      if (address === "" || !address.replace(/\s/g, "").length) return "-";

      return address;
    }

    return "-";
  }, [patientInfo]);

  const clinicAddress = useMemo(() => {
    let clinicAddress;
    if(patientInfo?.addressId){
      const findClinicAddress = clinicInfo?.multipleAdresses.find(obj=>obj.id==patientInfo.addressId);
      clinicAddress = findClinicAddress
    }

    if(clinicAddress){
      const address = `${clinicAddress?.address || ""}${
        clinicAddress?.address && clinicAddress?.city ? "," : ""
      } ${clinicAddress?.city || ""}${
        clinicAddress?.county && clinicAddress?.city ? "," : ""
      } ${clinicAddress?.county || ""}${
        clinicAddress?.county && clinicAddress?.zipCode ? "," : ""
      } ${clinicAddress?.zipCode || ""}${
        clinicAddress?.country && clinicAddress?.zipCode ? "," : ""
      } ${clinicAddress?.country || ""}`;
      if (address === "" || !address.replace(/\s/g, "").length) return "-";

      return address;
      // return "-";
    }
    else {
      if(clinicInfo){
        const address = `${clinicInfo?.clinic_address || ""}${
          clinicInfo?.clinic_address && clinicInfo?.clinic_city ? "," : ""
        } ${clinicInfo?.clinic_city || ""}${
          clinicInfo?.clinic_county && clinicInfo?.clinic_city ? "," : ""
        } ${clinicInfo?.clinic_county || ""}${
          clinicInfo?.clinic_county && clinicInfo?.clinic_postal_code ? "," : ""
        } ${clinicInfo?.clinic_postal_code || ""}${
          clinicInfo?.clinic_country && clinicInfo?.clinic_postal_code ? "," : ""
        } ${clinicInfo?.clinic_country || ""}`;
        if (address === "" || !address.replace(/\s/g, "").length) return "-";
  
        return address;
      }
      
    }
    return "-";
  }, [clinicInfo,patientInfo]);


  const getClinicName = useMemo(() => {
    let clinicAddress;
    if(patientInfo?.addressId){
      const findClinicAddress = clinicInfo?.multipleAdresses.find(obj=>obj.id==patientInfo.addressId);
      clinicAddress = findClinicAddress
    }

    if(clinicAddress){
     return  clinicAddress.label;
      // return "-";
    }
    else {
      if(clinicInfo){
        return "Address";
      }
    }
    return "-";
  }, [clinicInfo,patientInfo]);


  const getClinicTime = () => {
    const dummyDate = "2017-03-13";
    if (clinicInfo?.clinic_start_time) {
      return (
        moment(dummyDate + " " + clinicInfo?.clinic_start_time).format(
          "HH:mm"
        ) +
        " - " +
        moment(dummyDate + " " + clinicInfo?.clinic_end_time).format("HH:mm")
      );
    }
    return "-";
  };

  const getAlignerSTatusText = () => {
    const suffix = companyType === 4 ? "Stage" : "Aligner";
    const alignerNum = currentAlignerNumber
      ? `${currentAlignerNumber}/${totalAlignerState}`
      : "0";
    return `Currrent ${suffix} ${alignerNum}`;
  };


  const handleCancel = () => {
    setShowEditModal(false);
  }

  return (
    <div style={{ width: "100%" }}>
      <div className="patient-detail-container-patientInfo">
        <div className="patient-detail-topRow">
          <div className="patient-detail-topRow-profile">
            <div className="profile-icon">
              {" "}
              <img src={UserIcon} className="user-icon-image" alt="Edit" />
            </div>
            <div className="patient-name">
              <div
                className={`${activeTab === "patient" ? "subheading sub-highlight" : "subheading"} ${
                  companyType === 5 ? "subheading-smilealign" : ""
                }`}
              >
                {activeTab === "patient"
                  ? `${patientInfo?.patient_first_name} ${patientInfo?.patient_last_name}`
                  : `${clinicInfo?.clinic_name}`}
                {activeTab === "patient" && (
                  <EditIcon
                    className="hoverIcon"
                    onClick={() => {
                      setShowEditModal(true);
                    }}
                    style={{
                      width: "14px",
                      marginLeft: "5px",
                      paddingTop: "3px",
                      cursor: "pointer",
                      marginTop: "-15px",
                      fill: primaryColor,
                    }}
                  />
                )}
              </div>
              <div className="caption">
                {activeTab === "patient"
                  ? patientInfo?.patient_id
                  : clinicInfo.clinic_id}
              </div>
            </div>
          </div>
          {showEditModal && (
            <EditPatientModal
              title={
                <div className="titleSpan">
                  <img
                    src={require("../../assets/images/editUser.png")}
                    style={{ width: "30px" }}
                    alt=""
                  />
                  <Title level={5} style={{ margin: "0px" }}>
                    EDIT PATIENT
                  </Title>
                </div>
              }
              IsEdit={true}
              content={patientInfo}
              showEditModal={showEditModal}
              handleCancel={handleCancel}
              setShowEditModal={setShowEditModal}
              editPatientForm={editPatientForm}
              patientInfo={patientInfo}
              clinicInfo={clinicInfo}
              getPatientInformation={getPatientInformation}
            />
          )}
          <div style={{ display: "flex" }}>
            {lab.labServiceId !== 1 && (
              <div className="aligner">
                <AppTag text={getAlignerSTatusText()} />
              </div>
            )}
            <Radio.Group
              defaultValue={PATIENT}
              value={activeTab}
              buttonStyle="solid"
              style={{ marginTop: "20px", height: "40px", marginRight: "10px" }}
              onChange={onTypeChanged}
            >
              <Radio.Button
                className="patient-details-radio-buttons"
                value={PATIENT}
              >
                Patient
              </Radio.Button>
              <Radio.Button
                className="patient-details-radio-buttons"
                value={CLINIC}
              >
                Clinician
              </Radio.Button>
            </Radio.Group>
            {/* </div> */}
          </div>
        </div>
        <div className="patient-detail-bottomRow patient-detail-middleRow ">
          <div className="patient-info">
            <div
              style={{
                height: "80%",
                width: "200px",
                marginTop: "5px",
                marginLeft: "10px",
                borderRight: "2px solid #e9e9e9",
                display: "flex",
                flexDirection: "column",
                alignItems: "baseline",
              }}
            >
              <div className="caption">
                {activeTab === "patient" ? "DOB" : "Hours"}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "5px",
                  marginTop: "3px",
                }}
              >
                <CalendarIcon stroke={primaryColor} />
                <div className="content" style={{ marginLeft: "8px" }}>
                  {activeTab === "patient"
                    ? patientInfo?.patient_date_of_birth
                      ? dayjs(patientInfo?.patient_date_of_birth).format("DD-MMM-YYYY")
                      : "-"
                    : getClinicTime()}
                </div>
              </div>
            </div>
            {activeTab === "patient" ? (
              <div
                style={{
                  height: "80%",
                  width: "200px",
                  marginTop: "5px",
                  marginLeft: "10px",
                  borderRight: "2px solid #e9e9e9",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "baseline",
                }}
              >
                <div className="caption">Gender</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: "5px",
                    marginTop: "3px",
                  }}
                >
                  <UserOutlined style={{ color: primaryColor }} />
                  <div className="content" style={{ marginLeft: "8px" }}>
                    <p className="gender">
                      <Tooltip
                        title={getDisplayGender(patientInfo)}
                        placement="topLeft"
                      >
                        <span>{getDisplayGender(patientInfo)}</span>
                      </Tooltip>
                    </p>
                  </div>
                </div>
              </div>
            ) : null}
            <div
              style={{
                height: "80%",
                width: "200px",
                marginTop: "5px",
                borderRight: "2px solid #e9e9e9",
                display: "flex",
                flexDirection: "column",
                alignItems: "baseline",
              }}
            >
              <div className="caption">Mobile No</div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "12px",
                  marginTop: "3px",
                }}
              >
                <SmartphoneIcon stroke={primaryColor} />
                <div className="content" style={{ marginLeft: "8px" }}>
                  {activeTab === "patient"
                    ? patientInfo?.patient_country_code_name
                      ? `${patientInfo?.patient_country_code_name}-`
                      : ""
                    : ""}
                  {activeTab === "patient"
                    ? patientInfo?.patient_phone_number
                      ? patientInfo?.patient_phone_number
                      : "-"
                    : clinicInfo?.clinic_contact
                    ? clinicInfo?.clinic_contact
                    : "-"}
                </div>
              </div>
            </div>
            <div
              style={{
                height: "80%",
                width: "300px",
                marginTop: "5px",
                borderRight: "2px solid #e9e9e9",
                display: "flex",
                flexDirection: "column",
                alignItems: "baseline",
              }}
            >
              <div
                className="caption"
                style={{
                  marginLeft: "12px",
                }}
              >
                Email Id
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "12px",
                  marginTop: "3px",
                }}
              >
                <EmailIcon
                  style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                  alt="email"
                  fill={primaryColor}
                />
                <div
                  className="content email-content"
                  style={{ marginLeft: "8px" }}
                >
                  {activeTab === "patient"
                    ? patientInfo?.patient_email
                      ? patientInfo?.patient_email
                      : "-"
                    : clinicInfo?.clinic_email
                    ? clinicInfo?.clinic_email
                    : "-"}
                </div>
              </div>
            </div>

            <div
              style={{
                height: "80%",
                width: "300px",
                marginTop: "5px",
                borderRight:
                  activeTab !== "patient" ? "2px solid #e9e9e9" : "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "baseline",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  className="caption"
                  style={{ textAlign: "left", marginLeft: "15px" }}
                >
                  Address
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: "18px",
                    marginTop: "3px",
                  }}
                >
                  <AddressIcon fill={primaryColor} />
                  <div
                    className="content"
                    style={{ marginLeft: "8px", textAlign: "left" }}
                  >
                    {activeTab === "patient" ? patientAddress : clinicAddress}
                  </div>
                </div>
              </div>
            </div>
            {activeTab !== "patient" && (
              <div
                style={{
                  height: "80%",
                  marginTop: "5px",
                  display: "flex",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    className="caption"
                    style={{ textAlign: "left", marginLeft: "15px" }}
                  >
                    {activeTab === "patient" ? "" : "Clinic Name"}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: "18px",
                      marginTop: "3px",
                    }}
                  >
                    <UserOutlined style={{ color: primaryColor }} />
                    <div className="content" style={{ marginLeft: "8px" }}>
                      {getClinicName}
                      {/* {activeTab === "patient" ? patientAddress : clinicAddress} */}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  lab: state.lab,
  // error: state.error,
});

export default connect(mapStateToProps)(PatientsDetail);
